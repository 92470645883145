import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Checkbox, Table, Tabs, Tooltip } from "antd";
import Loader from "./Loader";
import { useAuth } from "../AuthContext";
import { SearchOutlined } from "@ant-design/icons";
import DropDownComponent from "../components/DropDownComponent";
import WebSiteLogo from "../assets/ContentIngestionTypes/website-icn.svg";
import SiteMapOption from "../components/WebsiteConnectorComponents/SiteMapOption";
import UrlOption from "../components/WebsiteConnectorComponents/UrlOption";
import HtmlOption from "../components/WebsiteConnectorComponents/HtmlOption";
import "../styles/websiteConnectors.css";
import log  from "./logger";

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}
export const StepOneWebsite = ({
  onNext,
  onBack,
  onBackToOptions,
  setFetchedUrls,
  setJobId,
  closeModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [source, setSource] = useState("");
  const [formData, setFormData] = useState({
    1: {},
    2: {},
    3: {},
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const { tokenDetails } = useAuth();
  useEffect(() => {
    const currentFormData = formData[activeTab];
    const tabIsValid =
      currentFormData &&
      Object.keys(currentFormData).length > 0 &&
      currentFormData.isValid;
    setIsFormValid(tabIsValid && source.trim() !== "");
  }, [activeTab, formData, source]);

  const setFormDataAndValidity = (tabId, tabData, isValid) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [tabId]: { ...tabData, isValid },
    }));
  };
  const handleSourceNameChange = (e) => {
    setSource(e.target.value);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const UploadFile = async (jobIdTemp) => {
    const fileData = new FormData();
    fileData.append("file", formData[2].sorConfig.file);
    for (let [key, value] of fileData.entries()) {
      if (value instanceof File) {
        log(
          `${key}: File Name=${value.name}, File Size=${value.size} bytes`
        );
      } else {
        log(`${key}: ${value}`);
      }
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/web/uploadFile?jobId=${jobIdTemp}`,
        {
          method: "POST",
          body: fileData,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      log("File upload successful:", data);
      closeModal();
      return data;
    } catch (error) {
      console.error("Failed to upload file", error);
    }
  };
  const uploadHtmlFiles = async (jobIdTemp) => {
    const fileData = new FormData();
    const htmlData = formData[3];
    log("htmlData", htmlData.sorConfig.files);
    htmlData.sorConfig.files.forEach((file) => {
      if (file) {
        log("Appending file: ", file.name);
        fileData.append("files[]", file);
      } else {
        console.error("Attempted to append an undefined file.");
      }
    });

    for (let [key, value] of fileData.entries()) {
      log("logging file info", key, value);
    }
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/web/uploadHtmlFiles?jobId=${jobIdTemp}`,
        {
          method: "POST",
          body: fileData,
        }
      );
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await res.json();
      log("File upload successful:", data);
      closeModal();
    } catch (error) {
      console.error("Failed to upload files", error);
    }
  };

  const tabItems = [
    {
      label: "Sitemap",
      key: "1",
      children: (
        <SiteMapOption setFormDataAndValidity={setFormDataAndValidity} />
      ),
    },
    {
      label: "URL",
      key: "2",
      children: <UrlOption setFormDataAndValidity={setFormDataAndValidity} />,
    },
    // {
    //   label: "HTML",
    //   key: "3",
    //   children: <HtmlOption setFormDataAndValidity={setFormDataAndValidity} />,
    // },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    log("Active tab:", activeTab);
    log("All formData:", formData);
    const currentTabData = formData[activeTab];
    let jobIdTemp;
    log(
      "Form submission for active tab",
      activeTab,
      ":",
      currentTabData,
      "Source Name:",
      source
    );
    if (!currentTabData || !currentTabData.isValid) {
      console.error("Form data is invalid or missing for the active tab");
      return;
    }
    const dataToSubmit = {
      ...currentTabData,
      sorConfig: {
        ...currentTabData.sorConfig,
        source: source,
      },
    };
    setLoading(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/initiateJob?userId=${tokenDetails.user.id}&agentId=${tokenDetails.agent_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSubmit),
        }
      );
      if (!res.ok) {
        throw new Error("Network res was not ok");
      }
      const data = await res.json();
      sessionStorage.setItem("initiatedJobId", data.jobId); //This is used to delete job if page reloaded midway/ user press cancel
      setJobId(data.jobId);
      jobIdTemp = data.jobId;
      if (activeTab === "1") {
        setFetchedUrls(data.urls);
        log("Data fetched successfully:", data);
        onNext();
      } else if (activeTab === "2") {
        const data = await UploadFile(jobIdTemp);
        log("logging the csv uploading file data response>>>", data);
      } else if (activeTab === "3") {
        const data = await uploadHtmlFiles(jobIdTemp);
        log("logging the html uploading file data response>>>", data);
      }
    } catch (error) {
      log("Failed to send data", error);
    } finally {
      await timeout(1000);
      setLoading(false);
      onNext();
    }
  };

  return (
    <section className="WebsiteConnectorTabs">
      {loading ? (
        <div
          className="loaderContainer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div className="webSiteConnectorsHeader">
            <div className="webSiteConnectorsContent">
              <h3>Configure Connection</h3>
              <p>
                Simplify the configuration of your connections by entering
                essential connection details, ensuring a seamless setup process
                for your selected data source
              </p>
            </div>
            <div className="webSiteLogo">
              <img src={WebSiteLogo} alt="webSite" />
            </div>
          </div>
          <div className="WebSiteConnectorsSourceNameInput">
            <h4>Source Name</h4>
            <Input
              placeholder="Enter Source Name"
              name="source"
              value={source}
              onChange={handleSourceNameChange}
            />
          </div>
          <Tabs
            defaultActiveKey="1"
            onChange={handleTabChange}
            type="card"
            items={tabItems}
            className="websiteConnectorTabs"
          />
          <div className="webSiteButtons">
            <Button
              onClick={() => (onBackToOptions ? onBackToOptions() : onBack())}
            >
              Back
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={handleSubmit}
              disabled={!isFormValid}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </section>
  );
};
export const StepTwoWebsite = ({onBack, onNext, fetchedUrls, jobId, setInitialUserProperties, setStepTwoWebsiteData }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedStatus, setExpandedStatus] = useState(null);
  const { tokenDetails } = useAuth();
  useEffect(() => {
    const initialData = fetchedUrls.map((site, index) => ({
      key: index.toString(),
      loc: site.loc,
      lastmod: site.lastmod || "Not provided",
      checked: false,
    }));
    setData(initialData);
  }, [fetchedUrls]);

  const deleteJob = async (jobId) => {
    log("entered back to options");
    const requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/deleteJob?jobId=${jobId}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (err) {
      log("error while deleting job", err);
    }
  };

  const handleBack = () => {
    onBack();
    log("logging jobid in back step", jobId);
    deleteJob(jobId);
    sessionStorage.removeItem("initiatedJobId");
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const filteredData = searchTerm
    ? data.filter((item) =>
        item.loc.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : data;
  const columns = [
    {
      title: "",
      dataIndex: "checked",
      key: "checked",
      render: (_, record) => (
        <Checkbox
          checked={record.checked}
          onChange={(e) => {
            const newData = [...data];
            const index = newData.findIndex((item) => item.key === record.key);
            newData[index].checked = e.target.checked;
            setData(newData);
          }}
        />
      ),
    },
    {
      title: "WEB URLS",
      dataIndex: "loc",
      width: "60%",
      key: "loc",
      render: (text, record) => (
        <div
          style={{
            maxWidth: "380px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer",
          }}
          onClick={() =>
            setExpandedStatus(expandedStatus === record.key ? null : record.key)
          }
        >
          {expandedStatus === record.key ? (
            text
          ) : (
            <Tooltip title={text}>{text}</Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "LAST MODIFIED",
      dataIndex: "lastmod",
      width: "30%",
      key: "lastmod",
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toLocaleString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "",
      key: "view",
      width: "10%",
      render: (_, record) => (
        <Button type="link" onClick={() => window.open(record.loc, "_blank")}>
          View
        </Button>
      ),
    },
  ];
  const paginationConfig = {
    pageSize: 20,
    showSizeChanger: false,
  };
  const handleNext = async () => {
    const checkedItems = data
      .filter((item) => item.checked)
      .map((item) => item.loc);
    log("Checked Items:", checkedItems);
    setStepTwoWebsiteData(checkedItems);
    setLoading(true);
    try {
      //insert getUserProp api here
      const fetchInitialOptions = async () => {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
        };
        let res = await fetch(
          `${process.env.REACT_APP_API_URL}/getUserProperties?agentId=${tokenDetails.agent_id}&accessToken=${tokenDetails.user.access_token}&instance=${tokenDetails.instance}`,
          requestOptions
        );
        res = await res.json();
        log("logging user properties in step three sharepoint", res);
        return res;
      };
      const initalUserOptions = await fetchInitialOptions();
      setInitialUserProperties(initalUserOptions);
      await timeout(2000);
      setLoading(false);
      onNext();

    } catch (error) {
      console.error("There was an error sending selected URLs:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="stepTwowebsite">
      {loading ? (
        <div
          className="loaderContainer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div className="stepTwowebsiteHeader">
            <div className="stepTwowebsiteContent">
              <h3>Select Sites</h3>
              <p>
                Enable users to conveniently select a group of websites for data
                ingestion
              </p>
            </div>
            <div className="stepTwowebsiteImage">
              <img src={WebSiteLogo} alt="website" />
            </div>
          </div>
          <div className="stepTwoCheckBox">
            <div className="stepTwoWebsiteConnectorHeading">
              <h4>Website Site Listings</h4>
              <div className="stepTwoWebsiteConnectorUrls">
                <h4>No. of Urls: {fetchedUrls.length}</h4>
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                  onChange={handleSearchChange}
                  style={{ width: 200, margin: "0 0" }}
                  loading={loading}
                  allowClear
                />
              </div>
            </div>
            <div className="stepTwoTableChechBox">
              <Table
                columns={columns}
                dataSource={filteredData}
                pagination={paginationConfig}
              />
            </div>
          </div>
          <div className="stepTwowebsitButtons">
            <Button onClick={handleBack}>Back</Button>
            <Button type="primary" onClick={handleNext}>
              Next
            </Button>
          </div>
        </>
      )}
    </section>
  );
};

export const StepThreeWebsite = ({
  onBack,
  jobId,
  closeModal,
  stepTwoWebsiteData,
  initialUserProperties,
}) => {
  const { tokenDetails } = useAuth();
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (formRef.current && formRef.current.getFormData) {
      const formData = formRef.current.getFormData();
      // const combinedData = {
      //   ...sharePointData,
      // };
      try {
        log("formData>", formData);
        log("initialValues>", initialUserProperties);
        let intialValues = initialUserProperties.map((item) => item.label);
        let customAttributes = Object.keys(formData).filter((item) => {
          return (
            formData[item] &&
            formData[item] !== "" &&
            !intialValues.includes(item)
          );
        });
        log("custom attributes>", customAttributes);
        await fetch(
          `${process.env.REACT_APP_API_URL}/setUserProperty?agentId=${tokenDetails.agent_id}&accessToken=${tokenDetails.user.access_token}&instance=${tokenDetails.instance}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(customAttributes),
          }
        );

        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/web/ingestUrls?jobId=${jobId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ urls: stepTwoWebsiteData, docAttributes: formData}),
          }
        );
  
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        const responseData = res.status;
        setLoading(false);
        log(responseData);
        sessionStorage.removeItem("initiatedJobId"); //Since job is in progress, we are clearing the initiatedJobId
        closeModal();
      } catch (error) {
        console.error("Failed to send data to API:", error);
      } finally {
        setLoading(false);
        closeModal();
      }
    }
  };
  return (
    <section className="stepFoursharePoint">
      {loading ? (
        <div
          className="loaderContainer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div className="stepFoursharepointConnectorsHeader">
            <div className="stepFoursharePointConnectorsContent">
              <h3>Set Document Attributes</h3>
              <p>
                Select document attributes applicable to the documents. These
                will be used for filtering based on user entitlements. Note that
                the attributes selected below are applicable across all
                documents selected for ingestion.
                <br />
                If you want to add new attributes(other than those displayed
                under "User Properties" section of the dashboard), please ensure
                that bot is in edit mode.
              </p>
            </div>
            <div className="sharePoint">
              <img src={WebSiteLogo} alt="SharePoint" />
            </div>
          </div>
          <div className="stepFoursharePointDocPropertiesHeading">
            <h4>Choose from the following properties</h4>
          </div>
          <div className="stepFoursharePointPropertiesSetting">
            <DropDownComponent
              ref={formRef}
              initialOptions={initialUserProperties}
            />
          </div>
          <div className="stepFoursharePointButtons">
            <Button onClick={onBack}>Back</Button>
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </>
      )}
    </section>
  );
};

const WebsiteConnector = ({ onBackToOptions, updateSubStep, closeModal }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [fetchedUrls, setFetchedUrls] = useState([]);
  const [jobId, setJobId] = useState(0);
  const [stepTwoWebsiteData, setStepTwoWebsiteData] = useState([]);
  const [initialUserProperties, setInitialUserProperties] = useState(null);
  
  const nextStep = () => {
    const newStep = currentStep + 1;
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
      updateSubStep(newStep);
    }
  };
  const prevStep = () => {
    const newStep = currentStep - 1;
    if (newStep >= 0) {
      setCurrentStep(newStep);
      updateSubStep(newStep);
    } else {
      onBackToOptions();
    }
  };
  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <StepOneWebsite
            onNext={nextStep}
            onBack={prevStep}
            onBackToOptions={onBackToOptions}
            setFetchedUrls={setFetchedUrls}
            setJobId={setJobId}
            closeModal={closeModal}
          />
        );
      case 1:
        return (
          <StepTwoWebsite
            onBack={prevStep}
            onNext={nextStep}
            fetchedUrls={fetchedUrls}
            jobId={jobId}
            setStepTwoWebsiteData={setStepTwoWebsiteData}
            setInitialUserProperties={setInitialUserProperties}
          />
        );
      case 2:
        return (
          <StepThreeWebsite
            closeModal={closeModal}
            onBack={prevStep}
            jobId={jobId}
            stepTwoWebsiteData={stepTwoWebsiteData}
            initialUserProperties={initialUserProperties}
          />
        );
    }
  };
  return <>{renderStep()}</>;
};

export default WebsiteConnector;
