import React, { useState, useCallback, useRef, useEffect } from "react";
import { Input, Button, Checkbox, Alert, Tabs, Form, Row, Col } from "antd";
import { useAuth } from "../AuthContext";
import DropDownComponent from "../components/DropDownComponent";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  CloseOutlined,
} from "@ant-design/icons";
import Loader from "./Loader";
import SharePointDocument from "../components/SharePointDocument";
import AnswerConfigComponent from "./AnswerConfigComponent";
import SharePointLogo from "../assets/ContentIngestionTypes/sharepoint-icn.svg";
import "../styles/sharePointConnectors.css";
import log from "./logger";

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}
export const StepOneSharePoint = ({
  onNext,
  onBack,
  onBackToOptions,
  setJobId,
  setsiteLists,
  formData,
  setFormData
}) => {
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [alert, setAlert] = useState({
    visible: false,
    message: "",
  });
  //this is to make the alert disapper
  useEffect(() => {
    if (alert.visible) {
      const timer = setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [alert]);
  const { tokenDetails } = useAuth();
  const handleChange = (event, section) => {
    const { name, value } = event.target;
    setFormData((prevState) => {
      const updatedFormData = {
        ...prevState,
        [section]: {
          ...prevState[section],
          [name]: value,
        },
      };
      const allFieldsFilled =
        Object.values(updatedFormData.sorConfig).every(
          (value) => value.trim() !== ""
        ) &&
        Object.values(updatedFormData.answersDetails).every(
          (value) => value.trim() !== ""
        );
      setIsFormValid(allFieldsFilled);
      return updatedFormData;
    });
    sessionStorage.setItem('formData', JSON.stringify(formData));
  };
  const updateAnswersDetails = useCallback((details) => {
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        answersDetails: { ...details },
      };
      const allFieldsFilled =
        Object.values(updatedFormData.sorConfig).every(
          (value) => value.trim() !== ""
        ) &&
        Object.values(updatedFormData.answersDetails).every(
          (value) => value.trim() !== ""
        );
      setIsFormValid(allFieldsFilled);
      return updatedFormData;
    });
    sessionStorage.setItem('formData', JSON.stringify(formData));
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    log("Logging the first step form input >>>", formData);
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/initiateJob?userId=${tokenDetails.user.id}&agentId=${tokenDetails.agent_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        let responseJson = await response.json();
        throw new Error(responseJson.message);
      }
      const data = await response.json();
      log("Response from api in step 1>>>>", data);
      sessionStorage.setItem("initiatedJobId", data.jobId); //This is used to delete job if page reloaded midway/ user press cancel
      setJobId(data.jobId);
      setsiteLists(data.sitesList);
      await timeout(1000);
      setLoading(false);
      onNext();
    } catch (error) {
      setLoading(false);
      log("Failed to send data", error);
      setAlert({ visible: true, message: error.message });
    }
  };
  return (
    <section className="sharePointConnectors">
      {loading ? (
        <div
          className="loaderContainer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {alert.visible && (
            <Alert
              message="Error"
              description={alert.message}
              type="error"
              closable
              showIcon
              style={{
                position: "fixed",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
                // zIndex: 1000,
                margin: "16px",
                padding: "8px 16px",
                fontSize: "14px",
                width: "70%",
                maxWidth: "500px",
              }}
            />
          )}
          <div className="sharePointConnectorsHeader">
            <div className="sharePointConnectorsContent">
              <h3>Configure Connection</h3>
              <p>
                Simplify the configuration of your connections by entering
                essential connection details, ensuring a seamless setup process
                for your selected data source
              </p>
            </div>
            <div className="sharePointLogo">
              <img src={SharePointLogo} alt="SharePoint" />
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="sharePointConnectorsInput">
              <div className="sharePointConnectorsInput1">
                <h4>Name<span style={{ color: 'red' }}>*</span></h4>
                <Input
                  placeholder="Enter a Name for the Job"
                  name="source"
                  value={formData.source || formData.sorConfig.source}
                  onChange={(e) => handleChange(e, "sorConfig")}
                />
                <h4>Sharepoint Tenant Name<span style={{ color: 'red' }}>*</span></h4>
                <Input
                  placeholder="Enter Tenant Name"
                  name="tenant"
                  value={formData.tenant || formData.sorConfig.tenant}
                  onChange={(e) => handleChange(e, "sorConfig")}
                />
                <h4>Sharepoint Tenant ID<span style={{ color: 'red' }}>*</span></h4>
                <Input
                  placeholder="Enter Tenant ID"
                  name="tenantId"
                  value={formData.tenantId || formData.sorConfig.tenantId}
                  onChange={(e) => handleChange(e, "sorConfig")}
                />
              </div>
              <div className="sharePointConnectorsInput2">
                <div className="sharePointClient">
                  <h4>OAuth Client ID<span style={{ color: 'red' }}>*</span></h4>
                  <Input
                    placeholder="Enter OAuth Client ID"
                    name="clientId"
                    value={formData.clientId || formData.sorConfig.clientId}
                    onChange={(e) => handleChange(e, "sorConfig")}
                  />
                </div>
                <div className="sharePointClient1">
                  <h4>OAuth Client Secret<span style={{ color: 'red' }}>*</span></h4>
                  <Input.Password
                    placeholder="Enter OAuth Client Secret"
                    name="clientSecret"
                    value={formData.clientSecret || formData.sorConfig.clientSecret}
                    onChange={(e) => handleChange(e, "sorConfig")}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </div>
              </div>
            </div>
            <AnswerConfigComponent
            documentGroupId={formData.answersDetails.documentGroupId}
              updateAnswersDetails={updateAnswersDetails}
            />
            <div className="sharePointButtons">
              <Button
                onClick={() => (onBackToOptions ? onBackToOptions() : onBack())}
              >
                Back
              </Button>
              <Button type="primary" htmlType="submit" disabled={!isFormValid}>
                Next
              </Button>
            </div>
          </form>
        </>
      )}
    </section>
  );
};
export const StepTwoSharePoint = ({
  onNext,
  onBack,
  jobId,
  siteLists,
  setStepTwosharePointData,
}) => {
  const [form] = Form.useForm();
  const [currentInputRef, setCurrentInputRef] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [alert, setAlert] = useState({
    visible: false,
    message: "",
  })
  const [checkboxes, setCheckboxes] = useState(
    siteLists.map((st) => ({
      label: st.name,
      id: st.id,
      checked: false,
    }))
  );
  const [loading, setLoading] = useState(false);
  const [inputFields, setInputFields] = useState([
    { id: Date.now(), value: "" },
  ]);
  const [isFormValid, setIsFormValid] = useState(false);
  const { tokenDetails } = useAuth();

  //this is to make the alert disapper
  useEffect(() => {
    if (alert.visible) {
      const timer = setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [alert]);
  const handleAddInput = () => {
    const newId = Date.now();
    setInputFields((fields) => [...fields, { id: Date.now(), value: "" }]);
    setCurrentInputRef(newId);
  };
  const handleRemoveInput = (id) => {
    setInputFields((fields) => fields.filter((field) => field.id !== id));
  };

  const handleKeyPress = (e, id) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddInput();
    }
  };
  useEffect(() => {
    if (currentInputRef) {
      document.getElementById(`input-${currentInputRef}`).focus();
      setCurrentInputRef(null);
    }
  }, [inputFields, currentInputRef]);

  const onCheckboxChange = (id) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.id === id
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    );
    setCheckboxes(updatedCheckboxes);
  };
  useEffect(() => {
    const anyChecked = checkboxes.some((checkbox) => checkbox.checked);
    const anyInputFilled = inputFields.some(
      (field) => field.value.trim() !== ""
    );
    setIsFormValid(
      (activeTabKey === "1" && anyChecked) ||
        (activeTabKey === "2" && anyInputFilled)
    );
  }, [checkboxes, inputFields, activeTabKey]);

  const handleNext = async () => {
    let reqData = [];
    if (activeTabKey === "1") {
      reqData = checkboxes.filter((checkbox) => checkbox.checked)
        .map((checkbox) => {return {'id': checkbox.id, 'name': checkbox.name}} ); //If we approach through Sites List tab(checked boxes), pass name and ID. If through selected Sites tab, pass only names
      
    } else if (activeTabKey === "2") {
      let filteredInputs = inputFields
        .map((field) => field.value.trim())
        .filter((value) => value !== "");
        reqData = filteredInputs.map((item) => {return {'name': item}})
    }

    log("Logging the sharepoint data:", reqData);
    setLoading(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/sharepoint/siteSelect?userId=${tokenDetails.user.id}&agentId=${tokenDetails.agent_id}&jobId=${jobId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(reqData),
        }
      );
      if (!res.ok) {
        let responseJson = await res.json();
        // log("entered error for sitesSelect>", responseJson);
        throw new Error(responseJson.message);
      }
      const data = await res.json();
      log("Response from api in step 2>>>>", data);
      setStepTwosharePointData(data.subsitesList);
      await timeout(1000);
      setLoading(false);
      onNext();
    } catch (error) {
      log("Faced error while fetching site artifacts:", error);
      setLoading(false);
      setAlert({ visible: true, message: error.message });
    }
  };

  const deleteJob = async (jobId) => {
    log("entered back to options");
    const requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/deleteJob?jobId=${jobId}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (err) {
      log("error while deleting job", err);
    }
  };
  const handleBack = () => {
    onBack();
    log("logging jobid in back step", jobId);
    deleteJob(jobId);
    sessionStorage.removeItem("initiatedJobId");
  };
  const handleInputChange = (id, value) => {
    setInputFields((fields) =>
      fields.map((field) => (field.id === id ? { ...field, value } : field))
    );
  };

  const items = [
    {
      key: "1",
      label: "Sites List",
      children: (
        <div className="stepTwoCheckBox">
          {checkboxes.length === 0 ? (
            <div className="stepTwoSharepointNodata">
              <Alert
                message="Informational Notes"
                description=" It seems like your application does not have sufficient
                permissions to view all sites. Please enter individual site
                names in the adjacent tab"
                type="info"
                showIcon
              />
            </div>
          ) : (
            <>
            {alert.visible && (
            <Alert
              message="Error"
              description={alert.message}
              type="error"
              closable
              showIcon
              style={{
                position: "fixed",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
                // zIndex: 1000,
                margin: "16px",
                padding: "8px 16px",
                fontSize: "14px",
                width: "70%",
                maxWidth: "500px",
              }}
            />
          )}
              <h4>Sharepoint Site Listings<span style={{ color: 'red' }}>*</span></h4>
              <div className="stepTwoCheckBoxContent">
                <div className="sharePointCheckBoxDiv">
                  {checkboxes.map((checkbox) => (
                    <Checkbox
                      key={checkbox.id}
                      checked={checkbox.checked}
                      className="sharePointCheckBox"
                      onChange={() => onCheckboxChange(checkbox.id)}
                    >
                      {checkbox.label}
                    </Checkbox>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: "Specific Sites",
      children: (
        <>
        <div className="stepTwoSharePointContent" style={{marginBottom: "10px"}}>
          <p>
            Enter the path of the site relative to root site. <a href="https://docs.avaamo.com" target="_blank" rel="noopener noreferrer">Click here</a> to learn more.
          </p>
        </div>
        <div style={{maxHeight: '340px', overflowY: 'auto'}}>
        <Form form={form}>
          {inputFields.map((field) => (
            <Row
              key={field.id}
              gutter={14}
              align="middle"
              style={{ marginBottom: "8px" }}
            >
              <Col span={10}>
                <Input
                  id={`input-${field.id}`}
                  value={field.value}
                  onChange={(e) => handleInputChange(field.id, e.target.value)}
                  onKeyPress={(e) => handleKeyPress(e, field.id)}
                  placeholder="Enter relative site path"
                />
              </Col>
              <Col span={4}>
                <Button
                  onClick={() => handleRemoveInput(field.id)}
                  icon={<CloseOutlined />}
                />
              </Col>
            </Row>
          ))}
          <Button onClick={handleAddInput} type="primary">
            Add Site
          </Button>
        </Form>
        </div>
        </>
      ),
    },
  ];
  return (
    <section className="stepTwoSharePoint">
      {loading ? (
        <div
          className="loaderContainer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
        {alert.visible && (
            <Alert
              message="Error"
              description={alert.message}
              type="error"
              closable
              showIcon
              style={{
                position: "fixed",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
                // zIndex: 1000,
                margin: "16px",
                padding: "8px 16px",
                fontSize: "14px",
                width: "70%",
                maxWidth: "500px",
              }}
            />
          )}
          <div className="stepTwoSharePointHeader">
            <div className="stepTwoSharePointContent">
              <h3>Select Sites</h3>
              <p>
                Enable users to conveniently select a group of SharePoint sites
                for data ingestion
              </p>
            </div>
            <div className="stepTwoSharePointImage">
              <img src={SharePointLogo} alt="SharePoint" />
            </div>
          </div>
          <div>
            <Tabs
              activeKey={activeTabKey}
              onChange={setActiveTabKey}
              items={items}
            />
          </div>
          <div className="stepTwoSharePointButtons">
            <Button onClick={handleBack}>Back</Button>
            <Button type="primary" onClick={handleNext} disabled={!isFormValid}>
              Next
            </Button>
          </div>
        </>
      )}
    </section>
  );
};
export const StepThreeSharePoint = ({
  onNext,
  onBack,
  stepTwosharePointData,
  setSharePointData,
  setInitialUserProperties,
}) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const { tokenDetails } = useAuth();
  const handleSelectionChange = useCallback(
    (selectedKeys) => {
      setSelectedIds(selectedKeys);
    },
    [setSelectedIds]
  );
  const stepThreeData = {
    selectedSubsiteIds: selectedIds,
  };
  const fetchInitialOptions = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
    };
    let res = await fetch(
      `${process.env.REACT_APP_API_URL}/getUserProperties?agentId=${tokenDetails.agent_id}&accessToken=${tokenDetails.user.access_token}&instance=${tokenDetails.instance}`,
      requestOptions
    );
    res = await res.json();
    log("logging user properties in step three sharepoint", res);
    return res;
  };
  const handleNext = async (event) => {
    event.preventDefault();
    log("logging all the selectedids in step three sharepoint", stepThreeData);
    setLoading(true);
    setSharePointData(stepThreeData);
    const initalUserOptions = await fetchInitialOptions();
    setInitialUserProperties(initalUserOptions);
    await timeout(2000);
    setLoading(false);
    onNext();
  };
  return (
    <section className="stepThreeSharePoint">
      {loading ? (
        <div
          className="loaderContainer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div className="stepThreeSharePointHeader">
            <div className="stepThreeSharePointContent">
              <h3>Select Documents Pages and Lists</h3>
              <p>
                Streamline content management in SharePoint by enabling users to
                effortlessly select documents, pages, and lists
              </p>
            </div>
            <div className="stepThreeSharePointImage">
              <img src={SharePointLogo} alt="SharePoint" />
            </div>
          </div>
          <div className="stepThreeSharePointDocument">
            <SharePointDocument
              stepTwosharePointData={stepTwosharePointData}
              onSelectionChange={handleSelectionChange}
            />
          </div>
          <div className="sharePointButtons">
            <Button onClick={onBack}>Back</Button>
            <Button type="primary" onClick={handleNext}>
              Next
            </Button>
          </div>
        </>
      )}
    </section>
  );
};
export const StepFourSharePoint = ({
  onBack,
  jobId,
  closeModal,
  sharePointData,
  initialUserProperties,
}) => {
  const { tokenDetails } = useAuth();
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (formRef.current && formRef.current.getFormData) {
      const formData = formRef.current.getFormData();
      const combinedData = {
        ...sharePointData,
        docAttributes: formData,
      };
      log("Combined data ready for API call in sharePoint", combinedData);
      try {
        log("formData>", formData);
        log("initialValues>", initialUserProperties);
        let intialValues = initialUserProperties.map((item) => item.label);
        let customAttributes = Object.keys(formData).filter((item) => {
          return (
            formData[item] &&
            formData[item] !== "" &&
            !intialValues.includes(item)
          );
        });
        log("custom attributes>", customAttributes);
        await fetch(
          `${process.env.REACT_APP_API_URL}/setUserProperty?agentId=${tokenDetails.agent_id}&accessToken=${tokenDetails.user.access_token}&instance=${tokenDetails.instance}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(customAttributes),
          }
        );
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/sharepoint/ingestSharepoint?jobId=${jobId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(combinedData),
          }
        );
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        const result = res.status;
        sessionStorage.removeItem("initiatedJobId"); //Since job is in progress, we are clearing the initiatedJobId
        log("Fourth step sharePoint status received", result);
        closeModal();
      } catch (error) {
        console.error("Failed to send data to API:", error);
      } finally {
        setLoading(false);
        closeModal();
      }
    }
  };
  return (
    <section className="stepFoursharePoint">
      {loading ? (
        <div
          className="loaderContainer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div className="stepFoursharepointConnectorsHeader">
            <div className="stepFoursharePointConnectorsContent">
              <h3>Set Document Attributes</h3>
              <p>
                Select document attributes applicable to the documents. These
                will be used for filtering based on user entitlements. Note that
                the attributes selected below are applicable across all
                documents selected for ingestion.
                <br/>
                <br/>
                Note: Attributes, if changed at the document group level will be overwritten each time the article gets updated.
              </p>
            </div>
            <div className="sharePoint">
              <img src={SharePointLogo} alt="SharePoint" />
            </div>
          </div>
          <div className="stepFoursharePointDocPropertiesHeading">
            <h4>Choose from the following properties</h4>
          </div>
          <div className="stepFoursharePointPropertiesSetting">
            <DropDownComponent
              ref={formRef}
              initialOptions={initialUserProperties}
            />
          </div>
          <div className="stepFoursharePointButtons">
            <Button onClick={onBack}>Back</Button>
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </>
      )}
    </section>
  );
};
const SharePointConnectors = ({
  onBackToOptions,
  updateSubStep,
  closeModal,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [siteLists, setsiteLists] = useState([]);
  const [jobId, setJobId] = useState(0);
  const [stepTwosharePointData, setStepTwosharePointData] = useState([]);
  const [sharePointData, setSharePointData] = useState(null);
  const [initialUserProperties, setInitialUserProperties] = useState(null);
  const [formData, setFormData] = useState({
    type: "sharepoint",
    sorConfig: {
      source: "",
      tenant: "",
      tenantId: "",
      clientId: "",
      clientSecret: "",
    },
    answersDetails: {
      documentGroupId: "",
      apiServer: "",
      accessToken: "",
    },
  });
  const nextStep = () => {
    const newStep = currentStep + 1;
    if (newStep < 4) {
      setCurrentStep(newStep);
      updateSubStep(newStep);
    }
  };

  const prevStep = () => {
    const newStep = currentStep - 1;
    if (newStep >= 0) {
      setCurrentStep(newStep);
      updateSubStep(newStep);
    } else {
      onBackToOptions();
    }
  };
  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <StepOneSharePoint
            onNext={nextStep}
            onBack={prevStep}
            onBackToOptions={onBackToOptions}
            setsiteLists={setsiteLists}
            setJobId={setJobId}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 1:
        return (
          <StepTwoSharePoint
            onNext={nextStep}
            onBack={prevStep}
            siteLists={siteLists}
            jobId={jobId}
            setStepTwosharePointData={setStepTwosharePointData}
            setFormData={setFormData}
          />
        );
      case 2:
        return (
          <StepThreeSharePoint
            onNext={nextStep}
            onBack={prevStep}
            jobId={jobId}
            setSharePointData={setSharePointData}
            stepTwosharePointData={stepTwosharePointData}
            setInitialUserProperties={setInitialUserProperties}
          />
        );
      case 3:
        return (
          <StepFourSharePoint
            closeModal={closeModal}
            onBack={prevStep}
            jobId={jobId}
            sharePointData={sharePointData}
            initialUserProperties={initialUserProperties}
          />
        );
    }
  };
  return <>{renderStep()}</>;
};

export default SharePointConnectors;
