import React from "react";
import { GrFormNext } from "react-icons/gr";
import "../styles/contentIngestionTypes.css";
function ContentIngestionType({ name, image, className, onClick }) {
  const isDisabled = !onClick;
  const handleClick = onClick ? () => onClick() : undefined;

  return (
    <div
      className={`contentingestiontype ${
        isDisabled ? "contentSourceDisabled" : ""
      }`}
      onClick={handleClick}
    >
      <div className="contenttypes">
        <img src={image} alt={name} />
        <h4>{name}</h4>
      </div>
      <div className="contentarrow">
        <div className="icon-circle">
          <GrFormNext size={22} />
        </div>
      </div>
    </div>
  );
}

export default ContentIngestionType;
