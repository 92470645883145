import React, { useState, useRef, useCallback, useEffect } from "react";
import { Input, Button, message } from "antd";
import AnswerConfigComponent from "../AnswerConfigComponent";
import "../../styles/WebsiteConnectorOptions/urlOption.css";
import log from "../logger";

const UrlOption = ({ setFormDataAndValidity }) => {
  const [filePath, setFilePath] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [urlOptionData, setUrlOptionData] = useState({
    type: "web",
    sorConfig: {
      file: "",
      filePath: "",
      webtype: "urls",
    },
    answersDetails: {
      documentGroupId: "",
      apiServer: "",
      accessToken: "",
    },
  });
  useEffect(() => {
    const isValid = Boolean(uploadedFile !== null || filePath.trim() !== "");
    setFormDataAndValidity(
      "2",
      {
        ...urlOptionData,
        sorConfig: {
          ...urlOptionData.sorConfig,
          file: file,
          filePath: filePath,
        },
      },
      isValid
    );
  }, [urlOptionData, uploadedFile, filePath]);

  const updateFormValidity = () => {
    Boolean(uploadedFile !== null || filePath.trim() !== "");
    log("filepath", filePath);
    setFormDataAndValidity(
      "2",
      {
        ...urlOptionData,
        sorConfig: {
          ...urlOptionData.sorConfig,
          file: file,
          filePath: filePath,
        },
      },
      Boolean(uploadedFile !== null || filePath.trim() !== "")
    );
  };
  const handleFilePathChange = (event) => {
    const newPath = event.target.value;
    setFilePath(newPath);
    setFile(null);
    setUploadedFile(null);
    updateFormValidity();
  };
  const updateAnswersDetails = useCallback((details) => {
    setUrlOptionData((prevFormData) => ({
      ...prevFormData,
      answersDetails: { ...details },
    }));
  }, []);
  const handleFileSelection = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFilePath(selectedFile.name);
      setFile(selectedFile);
      updateFormValidity();
    }
  };

  const handleUpload = () => {
    if (file) {
      setUrlOptionData((prev) => ({
        ...prev,
        sorConfig: {
          ...prev.sorConfig,
          file: file,
          filePath: file.name,
        },
      }));
      setUploadedFile(file);
      message.success(`${file.name} uploaded successfully!`);
    } else if (filePath.trim() !== "") {
      const simulatedFile = { name: filePath, size: "Unknown" };
      setUrlOptionData((prev) => ({
        ...prev,
        sorConfig: {
          ...prev.sorConfig,
          file: simulatedFile,
          filePath: filePath,
        },
      }));
      setUploadedFile(simulatedFile);
      message.success(`File at path "${filePath}" uploaded successfully!`);
    } else {
      message.error("Please enter a file path or select a file to upload.");
      setUploadedFile(null);
    }
  };
  const triggerFileDialog = () => {
    fileInputRef.current.click();
  };
  return (
    <section className="webSiteConnectorsUrl">
      <h4>CSV File</h4>
      <div className="webSiteConnectorsUrlInput">
        <Input
          value={filePath}
          onChange={handleFilePathChange}
          className="websiteurlInput"
          placeholder="Enter file path or browse to select"
          addonAfter={
            <Button
              className="websiteurlInputButton"
              onClick={triggerFileDialog}
            >
              Browse
            </Button>
          }
        />
        <input
          ref={fileInputRef}
          type="file"
          hidden
          onChange={handleFileSelection}
          accept=".csv"
        />
        <Button
          onClick={handleUpload}
          disabled={!filePath}
          className="websiteurlUpload"
        >
          Upload CSV
        </Button>
      </div>
      {uploadedFile && (
        <div>
          <p>
            Uploaded File: {uploadedFile.name} - Size: {uploadedFile.size}
          </p>
        </div>
      )}
      <AnswerConfigComponent updateAnswersDetails={updateAnswersDetails} />
    </section>
  );
};

export default UrlOption;
