import React from "react";
import { Popover, Steps } from "antd";
import "../styles/contentIngestionProgress.css";

const ContentIngestionProgressBar = ({
  currentMainStep,
  currentSubStep,
  sourceType,
}) => {
  const { Step } = Steps;
  const mainSteps = ["Content Source Type", "Configure"];
  let configureSubSteps = [];
  let isConfigureStepCompleted = false;
  if (sourceType === "SharePoint") {
    configureSubSteps = [
      "Configure Connection",
      "Select Sites",
      "Preview Artifacts",
      "Set Document Attributes",
    ];
  } else if (sourceType === "Salesforce" || sourceType === "ServiceNow") {
    configureSubSteps = [
      "Configure Connection",
      "Filter Articles",
      "Set Document Attributes",
    ];
  } else if (sourceType === "Website") {
    configureSubSteps = ["Configure Connection", "Filter WebPages", "Set Document Attributes"];
  } else if (sourceType === "Files") {
    configureSubSteps = ["Upload files"];
  }
  if (
    currentMainStep === 1 &&
    currentSubStep === configureSubSteps.length - 1
  ) {
    isConfigureStepCompleted = true;
  }

  const customDot = (dot, { status, index }) => (
    <Popover>
      {dot}
    </Popover>
  );

  return (
    <div className="contentIngestionProgress">
      <Steps
        current={currentMainStep}
        direction="vertical"
        progressDot={customDot}
      >
        {mainSteps.map((title, index) => {
          let stepStatus = "";
          if (index === 1 && isConfigureStepCompleted) {
            stepStatus = "finish";
          }
          return (
            <Step
              title={title}
              key={title}
              status={stepStatus}
              description={
                index === 1 && currentMainStep === 1 ? (
                  <Steps
                    direction="vertical"
                    size="small"
                    current={currentSubStep}
                    className="contentIngestionProgressSubsteps"
                  >
                    {configureSubSteps.map((subTitle, subIndex) => (
                      <Step
                        key={subTitle}
                        title={`${subIndex + 1}: ${subTitle}`}
                      />
                    ))}
                  </Steps>
                ) : null
              }
            />
          );
        })}
      </Steps>
    </div>
  );
};

export default ContentIngestionProgressBar;
