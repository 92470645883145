import React, { useState, useEffect } from "react";
import { Input, Table, Progress, Tooltip } from "antd";
import KebabMenu from "../../components/KebabMenu";
import ServiceNowLogo from "../../assets/ContentIngestionTypes/servicenow-icn.svg";
import { SearchOutlined } from "@ant-design/icons";
import "../../styles/ContentSourcesComponentsStyles/servicenowComponent.css";
import log from "../logger";

const ServiceNowComponent = ({ jobDetails, percentageJobDone, sourceName }) => {
  const [searchText, setSearchText] = useState("");
  const [uniqueStatusArray, setUniqueStatusArray] = useState([]);
  const [expandedStatus, setExpandedStatus] = useState(null);
  const [loading] = useState(false);
  log("logging jobdetails inside servoicebnow", jobDetails);

  const titleCase = (str) =>{
    return str && str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();//To make it title case
    });
  }
  
  useEffect(() => {
    setSearchText("");
    setUniqueStatusArray([]);
    log("Effect ran for jobId:", jobDetails.jobId);
    const uniqueStatus = new Set();
    const newUniqueStatusArray = [];
    jobDetails.processedArticles.forEach((servicenow) => {
      let displayStatus = titleCase(servicenow.status);
      if (servicenow.status.toLowerCase().includes("failed")) {
        displayStatus = "Error";
      } 
      if (!uniqueStatus.has(displayStatus)) {
        uniqueStatus.add(displayStatus);
        newUniqueStatusArray.push({
          text: displayStatus,
          value: servicenow.status,
        });
      }
    });
    setUniqueStatusArray(newUniqueStatusArray);
    log("Unique statuses updated", newUniqueStatusArray);
  }, [jobDetails.jobId, jobDetails.processedArticles]);

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const filterData = (status, value) =>{
    if(["ingested", "queued", "in progress", "unprocessable"].includes(value.toLowerCase())){
      return status.toLowerCase()===value.toLowerCase();
    }else{
      return !["ingested", "queued", "in progress", "unprocessable"].includes(status.toLowerCase());
    }
  }
  const columns = [
    {
      title: "NUMBER",
      dataIndex: "number",
      key: "number",
      width: "14%",
    },
    {
      title: "DOCUMENT TYPE",
      dataIndex: "docType",
      key: "docType",
      width: "20%",
    },
    {
      title: "TITLE",
      dataIndex: "name",
      width: "48%",
      key: "name",
      render: (text, record) => (
        <div
          style={{
            maxWidth: "450px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer",
          }}
          onClick={() =>
            setExpandedStatus(expandedStatus === record.key ? null : record.key)
          }
        >
          {expandedStatus === record.key ? (
            text
          ) : (
            <Tooltip title={text}>{text}</Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      filters: uniqueStatusArray,
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => filterData(record.status, value),
      width: "10%",
      key: "status",
      render: (status, record) => {
        let color = "black";
        let displayStatus = status;
        let tooltipTitle = ""; 
        if (status === "Ingested") {
          color = "green";
        } else if (
          status === "Queued" ||
          status === "In Progress" ||
          status === "Unprocessable"
        ) {
          color = "black";
        } else {
          color = "red";
          displayStatus = "Error";
          tooltipTitle = status;
        }
        return (
          <div
          style={{
            maxWidth: "450px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer",
            color: color,
          }}
          onClick={() =>
            setExpandedStatus(expandedStatus === record.key ? null : record.key)
          }
        >
          {expandedStatus === record.key ? (
            displayStatus
          ) : (
          <Tooltip title={tooltipTitle}>{displayStatus}</Tooltip>
          )}
        </div>
        )
      },
    },
    {
      title: "ACTION",
      key: "action",
      width: "10%",
      render: (text, record) => (
        record.url &&
        <a href={record.url} target="_blank" rel="noopener noreferrer">
          View
        </a>
      ),
    },
  ];
  const data = jobDetails.processedArticles.map((serviceNow, index) => ({
    key: index,
    number: serviceNow.number,
    status: titleCase(serviceNow.status),
    name: serviceNow.name,
    docType: serviceNow.documentType,
    url: serviceNow.url,
  }));

  const errorCount = jobDetails.processedArticles.filter(
    (article) =>
      article.status !== "ingested" &&
      article.status !== "in progress" &&
      article.status !== "queued" &&
      article.status !== "unprocessable"
  ).length;
  const articleCount = jobDetails.processedArticles.filter(
    (article) => article.documentType === "Article"
  ).length;
  const attachmentCount = jobDetails.numberOfArticles - articleCount;
  const filteredData = searchText
    ? data.filter((item) =>
     [item.name, item.number, item.docType].some(column => column.toLowerCase().includes(searchText.toLowerCase())) 
    )
    : data;
  const paginationConfig = {
    pageSize: 20,
    showSizeChanger: false,
  };
  return (
    <section className="ServiceNowComponent">
      <div className="ServiceNowComponentHeader">
        <div className="ServiceNowComponentSubHeader">
          <img src={ServiceNowLogo} alt="ServiceNow" />
          <h4>{sourceName}</h4>
          <KebabMenu jobId={jobDetails.jobId} type={"servicenow"} />
        </div>
        {/* <div className="ServiceNowComponentHeaderLastSynced">
          <span className="SharePointSpanLastSynced">Last Synced :</span>
          <span>2nd Oct 2023 / 10:40 am</span>
        </div> */}
      </div>
      <div className="ServiceNowComponentMetrics">
        <div className="ServiceNowComponentMetrics1">
          <span>Total No of Documents</span>
          <h5>{jobDetails.numberOfArticles || 0}</h5>
        </div>
        <div className="ServiceNowComponentMetrics2">
          <span>Articles | Attachment</span>
          <h5>
            {articleCount} | {attachmentCount}
          </h5>
        </div>
        <div className="ServiceNowComponentMetrics3">
          <span>Errors</span>
          <h5>{errorCount}</h5>
        </div>
      </div>
      <div>
        <div className="ServiceNowComponentFilter">
          <div className="ServiceNowComponentFilterHeader">
            <span>Knowledge Articles</span>
            {percentageJobDone!==0 && percentageJobDone < 100 && (
              <div style={{ display: "flex", marginLeft:"2%", alignItems: "center", width: "40%" }}>
                <Progress
                  percent={percentageJobDone}
                  size={[120, 10]}
                  status="active"
                  className="progressBar"
                  showInfo={false}
                />
                <span
                  style={{ marginLeft: 25 }}
                >{`${percentageJobDone}%`}</span>
              </div>
            )}
          </div>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={handleSearchInputChange}
            onPressEnter={() => filteredData(searchText)}
            style={{ width: 200, margin: "0 0" }}
            loading={loading}
            allowClear
          />
        </div>
        <div className="ServiceNowComponentTable">
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={paginationConfig}
            rowKey="key"
            loading={loading}
            // getPopupContainer={(trigger) => trigger.parentNode}
          />
        </div>
      </div>
    </section>
  );
};

export default ServiceNowComponent;
