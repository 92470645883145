import React, { useState } from "react";
import { Input, Button } from "antd";
import DownloadLogo from "../assets/download.svg";
import { message, Upload } from "antd";
import FilesLogo from "../assets/ContentIngestionTypes/files-icn.svg";
import "../styles/filesContent.css";
import log  from "./logger";

const AddFileContent = ({ onBackToOptions, updateSubStep }) => {
  const [currentStep] = useState(0);
  const [fileList, setFileList] = useState([]);
  const { Dragger } = Upload;
  const props = {
    name: "file",
    multiple: true,
    showUploadList: false,
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      setFileList(info.fileList);
    },
    fileList,
    onDrop(event) {
      log("Dropped files", event.dataTransfer.files);
    },
  };
  const prevStep = () => {
    const newStep = currentStep - 1;
    updateSubStep(newStep);
    onBackToOptions();
  };
  const removeFile = (file) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(newFileList);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  return (
    <section className="filesSection">
      <div className="filesContent">
        <div className="filesContentHeading">
          <h3>Upload Files</h3>
          <p>
            Simplify data ingestion by easily including your documents, making
            it straightforward to integrate essential information into your
            system
          </p>
        </div>
        <div className="filesLogo">
          <img src={FilesLogo} alt="Files" />
        </div>
      </div>
      <div className="fileSourceName">
        <h4>Source Name</h4>
        <Input placeholder="Enter a Source Name" />
      </div>
      <div className="addFiles">
        <h3>Add Files</h3>
        <Dragger {...props} className="uploadFilesContent">
          <img src={DownloadLogo} alt="Upload Files" />
          <p className="ant-upload-text">
            Drop your PDF, DOCX or TXT files here!
          </p>
        </Dragger>
        <div className="addedFilesList">
          <div className="addedFileSource">
            <h3>SOURCE NAME</h3>
          </div>
          <div className="addedFileDescription">
            <h3>FORMAT</h3>
            <h3>SIZE</h3>
            <h3>ACTIONS</h3>
          </div>
        </div>
        <div className="allTheFilesList">
          {fileList.map((file) => (
            <div className="addedFile" key={file.uid}>
              <div className="addedFileSource">{file.name}</div>
              <div className="addedFileDescription">
                <div>{file.type || "Unknown"}</div>
                <div className="addedFileSize">{formatBytes(file.size)}</div>
                <div className="addedFileActions">
                  <Button
                    size="small"
                    onClick={() => log("View", file)}
                  >
                    View
                  </Button>
                  <Button
                    size="small"
                    type="danger"
                    onClick={() => removeFile(file)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="addFilesButtons">
        <Button
          onClick={() => (onBackToOptions ? onBackToOptions() : prevStep)}
        >
          Back
        </Button>
        <Button type="primary">Save</Button>
      </div>
    </section>
  );
};

export default AddFileContent;
