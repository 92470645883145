import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContentSourcesAddition from "./pages/ContentSourcesAddition";
import "./App.css";
function App() {
  return (
    <div className=".app-container">
      <BrowserRouter>
        <div className="page-content">
          <Routes>
            <Route path="/" element={<ContentSourcesAddition />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
