import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Select, Input, Button, Form, Row, Col } from "antd";
import { CloseOutlined } from "@ant-design/icons";

let customOptionCounter = 0;

const DropDownComponent = forwardRef(({ initialOptions }, ref) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [form] = Form.useForm();
  const { Option } = Select;
  const [selectValue, setSelectValue] = useState(null); // Track the value of the Select component

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      return inputValues;
    },
  }));

  const handleSelectChange = (value, option) => {
    setSelectValue(null); // Reset the Select value to null to show the placeholder

    if (value === "customOption") {
      customOptionCounter += 1;
      const customKey = `custom-${customOptionCounter}`;
      setSelectedOptions((prevOptions) => [
        ...prevOptions,
        { key: customKey, label: "", isCustom: true },
      ]);
    } else {
      setSelectedOptions((prevOptions) => [
        ...prevOptions,
        {
          key: value,
          value: option.children,
          label: option.children,
          isCustom: false,
        },
      ]);
    }
  };

  const handleInputChange = (value, key) => {
    const isFinalValue = selectedOptions.some((option) =>
      option.isCustom ? option.label === key : option.value === key
    );

    if (isFinalValue) {
      setInputValues((prevValues) => ({
        ...prevValues,
        [key]: value,
      }));
    }
  };

  const handleCustomNameChange = (value, key) => {
    const updatedOptions = selectedOptions.map((option) => {
      if (option.key === key) {
        return { ...option, label: value };
      }
      return option;
    });
    setSelectedOptions(updatedOptions);
    const oldValue = inputValues[key];
    const newInputValues = { ...inputValues, [value]: oldValue };
    delete newInputValues[key];
    setInputValues(newInputValues);
  };

  const handleRemoveOption = (key, isCustom) => {
    const newSelectedOptions = selectedOptions.filter(
      (option) => option.key !== key
    );
    setSelectedOptions(newSelectedOptions);

    if (isCustom) {
      const option = selectedOptions.find((option) => option.key === key);
      const { [option.label]: _, ...newInputValues } = inputValues;
      setInputValues(newInputValues);
    } else {
      const { [key]: _, ...newInputValues } = inputValues;
      setInputValues(newInputValues);
    }
  };

  return (
    <Form form={form}>
      {selectedOptions.map((option) => (
        <Row
          key={option.key}
          gutter={14}
          align="middle"
          style={{ marginBottom: "8px" }}
        >
          <Col span={7}>
            {option.isCustom ? (
              <Input
                value={option.label}
                onChange={(e) =>
                  handleCustomNameChange(e.target.value, option.key)
                }
                placeholder="Attribute name"
              />
            ) : (
              <Input value={option.label} disabled />
            )}
          </Col>
          <Col span={9}>
            <Input
              value={inputValues[option.isCustom ? option.label : option.value]}
              onChange={(e) =>
                handleInputChange(
                  e.target.value,
                  option.isCustom ? option.label : option.value
                )
              }
              placeholder="Enter value"
            />
          </Col>
          <Col span={2}>
            <Button
              onClick={() => handleRemoveOption(option.key, option.isCustom)}
              icon={<CloseOutlined />}
            />
          </Col>
        </Row>
      ))}
      <Select
        showSearch
        style={{ maxWidth: "230px", width: "100%" }}
        placeholder="Select or add custom attribute"
        onSelect={handleSelectChange}
        value={selectValue}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {initialOptions &&
          initialOptions
            .filter(
              (option) => !selectedOptions.find((o) => o.value === option.value)
            )
            .map((option) => (
              <Option key={option.value}>{option.label}</Option>
            ))}
        <Option key="customOption">+ Additional Property</Option>
      </Select>
    </Form>
  );
});

export default DropDownComponent;
