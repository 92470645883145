import React from "react";
import "../styles/addedContent.css";
function AddedContent({ name, contentType, image, isSelected, onSelect }) {
  return (
    <div
      className={`addedContentMain ${isSelected ? "selected" : ""}`}
      onClick={onSelect}
    >
      <div className="addedContentHeader">
        <h6>{contentType}</h6>
        <h5 className={`name ${isSelected ? "selectedName" : ""}`} title={name}>
          {name}
        </h5>
      </div>
      <div className="addedContentImage">
        <img src={image} alt={contentType} />
      </div>
    </div>
  );
}

export default AddedContent;
