import React, { useState, useCallback, useRef, useEffect } from "react";
import { Input, Button, Checkbox, Alert } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import DropDownComponent from "./DropDownComponent";
import { useAuth } from "../AuthContext";
import SalesForceLogo from "../assets/ContentIngestionTypes/salesforce-icn.svg";
import AnswerConfigComponent from "./AnswerConfigComponent";
import Loader from "./Loader";
import "../styles/salesForceConnector.css";
import log  from "./logger";


function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

export const StepOneSalesForce = ({ onNext, onBack, onBackToOptions, setJobId, setArticleTypes }) => {
  const [formData, setFormData] = useState({
    type: "salesforce",
    sorConfig: {
      source: "",
      sourceUrl: "",
      clientId: "",
      clientSecret: "",
    },
    answersDetails: {
      documentGroupId: "",
      apiServer: "",
      accessToken: "",
    },
  });

  const [loading, setLoading] = useState(false);
  const { tokenDetails } = useAuth();
  const [isFormValid, setIsFormValid] = useState(false);
  const [alert, setAlert] = useState({
    visible: false,
    message: "",
  });

  //this is to make the alert disapper
  useEffect(() => {
    if (alert.visible) {
      const timer = setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [alert]);

  const isFieldValid = (value) => {
    // if (typeof value === "boolean") return true;
    if (typeof value === "string") return value.trim() !== "";
    return false;
  };
  const validateForm = (formData) => {
    const sorConfigValid = Object.values(formData.sorConfig).every(
      isFieldValid
    );
    const answersDetailsValid = Object.values(formData.answersDetails).every(
      isFieldValid
    );
    return sorConfigValid && answersDetailsValid;
  };

  const handleChange = (event, section) => {
    const { name, value } = event.target;
    setFormData((prevState) => {
      const updatedFormData = {
        ...prevState,
        [section]: {
          ...prevState[section],
          [name]: value,
        },
      };
      const formIsValid = validateForm(updatedFormData);

      setIsFormValid(formIsValid);
      return updatedFormData;
    });
  };

  const updateAnswersDetails = useCallback((details) => {
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        answersDetails: { ...details },
      };
      const formIsValid = validateForm(updatedFormData);
      setIsFormValid(formIsValid);
      return updatedFormData;
    });
  }, []);

  const handleSubmit = async (event) => {
    log("formData>>", formData);
    event.preventDefault();
    setLoading(true);
    setAlert({ visible: false, message: "" });
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/initiateJob?userId=${tokenDetails.user.id}&agentId=${tokenDetails.agent_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to initiate job");
      }
      setJobId(data.jobId);
      sessionStorage.setItem("initiatedJobId", data.jobId); //This is used to delete job if page reloaded midway/ user press cancel
      log("Data after sending in first step salesforce:", data);
      setArticleTypes(data.articleTypes);
      await timeout(1000);
      setLoading(false);
      onNext();
    } catch (error) {
      setLoading(false);
      log("Failed to send data", error);
      setAlert({ visible: true, message: error.message });
    }
  };

  return (
    <section className="salesForceConnectors">
      { loading ? (
        <div
          className="loaderContainer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60%",
          }}
        >
          <Loader />
        </div>
      ):(
        <>
        {alert.visible && (
            <Alert
              message="Error"
              description={alert.message}
              type="error"
              closable
              showIcon
              style={{
                position: "fixed",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
                // zIndex: 1000,
                margin: "16px",
                padding: "8px 16px",
                fontSize: "14px",
                width: "70%",
                maxWidth: "500px",
              }}
            />
          )}
      <div className="salesForceConnectorsHeader">
        <div className="salesForceConnectorsContent">
          <h3>Configure Connection</h3>
          <p>
            Simplify the configuration of your connections by entering essential
            connection details, ensuring a seamless setup process for your
            selected data source
          </p>
        </div>
        <div className="salesForceLogo">
          <img src={SalesForceLogo} alt="salesForce" />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="salesForceConnectorsInput">
          <div className="salesForceConnectorsInput1">
            <h4>Source Name</h4>
            <Input
              placeholder="Enter a Source Name"
              name="source"
              value={formData.source}
              onChange={(e) => handleChange(e, "sorConfig")}
            />
            <h4>Source URL</h4>
            <Input
              placeholder="Enter Source URL"
              name="sourceUrl"
              value={formData.sourceUrl}
              onChange={(e) => handleChange(e, "sorConfig")}            />
          </div>
          <div className="salesForceConnectorsInput2">
            <div className="salesForceOAuthClientID">
              <h4>OAuth Client ID</h4>
              <Input
                placeholder="Enter OAuth Client ID"
                name="clientId"
                value={formData.clientId}
                onChange={(e) => handleChange(e, "sorConfig")}
              />
            </div>
            <div className="salesForceOAuthClientSecret">
              <h4>OAuth Client Secret</h4>
              <Input
                placeholder="Enter OAuth Client Secret"
                name="clientSecret"
                value={formData.clientSecret}
                onChange={(e) => handleChange(e, "sorConfig")}
              />
            </div>
          </div>
        </div>
        <AnswerConfigComponent
              updateAnswersDetails={updateAnswersDetails}
          />
        <div className="salesForceButtons">
          <Button
            onClick={() => (onBackToOptions ? onBackToOptions() : onBack())}
          >
            Back
          </Button>
          <Button type="primary" htmlType="submit" disabled={!isFormValid}>
            Next
          </Button>
        </div>
      </form>
      </>
      )}
    </section>
  );
};
export const StepTwoSalesForce = ({ onNext, onBack, articleTypes, jobId, setSalesForceData, setInitialUserProperties }) => {
  const { TextArea } = Input;
  const { tokenDetails } = useAuth();
  const [checkboxes, setCheckboxes] = useState(articleTypes.map((label) => ({ label: label.name, id: label.id, checked: false })));
  const [customQuery, setCustomQuery] = useState("");
  const [published, setPublished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [alert, setAlert] = useState({
    visible: false,
    message: "",
  });

  useEffect(() => {
    if (alert.visible) {
      const timer = setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [alert]);

  const onCheckboxChange = (label) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.label === label
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    );
    setCheckboxes(updatedCheckboxes);
    log("checkbox set>>", updatedCheckboxes);
  };

  useEffect(() => {
    const anyCheckboxChecked = checkboxes.some((checkbox) => checkbox.checked);
    // const isAnyAdvancedFilterSelected = published;
    const isCustomQueryEntered = customQuery.trim().length > 0;
    setIsFormValid(
      anyCheckboxChecked || isCustomQueryEntered
    );
  }, [checkboxes, customQuery]);

  const onCustomQueryChange = (event) => {
    setCustomQuery(event.target.value);
  };
  const onOnlyPublishedChange = (event) => {
    setPublished(event.target.checked);
  };

  const fetchInitialOptions = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
    };
    let res = await fetch(
      `${process.env.REACT_APP_API_URL}/getUserProperties?agentId=${tokenDetails.agent_id}&accessToken=${tokenDetails.user.access_token}&instance=${tokenDetails.instance}`,
      requestOptions
    );
    res = await res.json();
    log("logging user properties options>", res);
    return res;
  };

  const handleNext = async(event) => {
    event.preventDefault();
    setLoading(true);

    let arr = [];
    checkboxes.map(item =>{
      // log("checkbox selected>>>", item);
      if(item.checked) arr.push(item.id);
    })

    if(customQuery && !/\bhttps:\/\/(www\.)?([a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,})(\/[a-zA-Z0-9\-\.]*)*\b/.test(customQuery)){
      // await timeout(1000);
      setLoading(false);
      setAlert({ visible: true, message: "Invalid URL for custom Query"});
    }else{
      const salesForceData = {
        articleTypesSelected: arr,
        published,
        customQuery,
      };

      log("setting SFData:", salesForceData);
      setSalesForceData(salesForceData);
      const initalUserOptions = await fetchInitialOptions();
      setInitialUserProperties(initalUserOptions);
      await timeout(2000);
      setLoading(false);
      onNext();
    }
  };

  const deleteJob = async (jobId) => {
    log("entered back to options");
    const requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/deleteJob?jobId=${jobId}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (err) {
      log("error while deleting job", err);
    }
  };

  const handleBack = () => {
    onBack();
    log("logging jobid in back step", jobId);
    deleteJob(jobId);
    sessionStorage.removeItem("initiatedJobId");
  };
  return (
    <section className="stepTwosalesForce">
            {loading ? (
        <div
          className="loaderContainer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
        {alert.visible && (
              <Alert
                message="Error"
                description={alert.message}
                type="error"
                closable
                showIcon
                style={{
                  position: "fixed",
                  top: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  // zIndex: 1000,
                  margin: "16px",
                  padding: "8px 16px",
                  fontSize: "14px",
                  width: "70%",
                  maxWidth: "500px",
                }}
              />
            )}
      <div className="stepTwosalesForceConnectorsHeader">
        <div className="stepTwosalesForceConnectorsContent">
          <h3>Filter Articles</h3>
          <p>
            Optimize your search experience with the 'Filter Articles'
            functionality, enabling you to choose from various knowledge bases
            and implement advanced filters for precise and tailored results.
          </p>
        </div>
        <div className="salesForceLogo">
          <img src={SalesForceLogo} alt="SalesForce" />
        </div>
      </div>
      <div className="stepTwosalesForceCheckBox">
        <h4>By Types</h4>
        <div className="stepTwosalesForceCheckBoxContent">
          <div className="salesForceCheckBoxDiv">
            {checkboxes.map((checkbox) => (
              <Checkbox
                key={checkbox.label}
                checked={checkbox.checked}
                className="salesForceCheckBox"
                onChange={() => onCheckboxChange(checkbox.label)}
              >
                {checkbox.label}
              </Checkbox>
            ))}
          </div>
        </div>
      </div>
      <div className="stepTwosalesForceAdvancedFilters">
        <Checkbox
          className="salesForceCheckBox"
          checked={published}
          onChange={onOnlyPublishedChange}
        >
          Select only published articles
        </Checkbox>
      </div>
      <div className="stepTwosalesForceCustomInput">
        <h4>Custom Query to Select the Knowledge Articles</h4>
        <TextArea
          value={customQuery}
          onChange={onCustomQueryChange}
          autoSize={{
            minRows: 3,
            maxRows: 5,
          }}
        />
      </div>
      <div className="stepTwosalesForceButtons">
        <Button onClick={handleBack}>Back</Button>
        <Button type="primary" onClick={handleNext} disabled={!isFormValid}>
          Next
        </Button>
      </div>
      </>
      )}
    </section>
  );
};
export const StepThreeSalesForce = ({
  onBack,
  jobId,
  closeModal,
  salesForceData,
  initialUserProperties,
}) => {
  const [loading, setLoading] = useState(false);
  const { tokenDetails } = useAuth();
  const formRef = useRef(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (formRef.current && formRef.current.getFormData) {
      const formData = formRef.current.getFormData();
      const combinedData = {
        ...salesForceData,
        docAttributes: formData,
      };
      log("Combined data ready for API call in salesForceData>>", combinedData);
      try {
        // log("formData>", formData);
        log("initialValues>", initialUserProperties);
        let intialValues = initialUserProperties.map((item) => item.label);
        let customAttributes = Object.keys(formData).filter((item) => {
          return (
            formData[item] &&
            formData[item] !== "" &&
            !intialValues.includes(item)
          );
        });
        log("custom attributes>", customAttributes);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/setUserProperty?agentId=${tokenDetails.agent_id}&accessToken=${tokenDetails.user.access_token}&instance=${tokenDetails.instance}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(customAttributes),
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        //delay for sometime to ensure user propety is created on platform before assigning value

        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/salesforce/ingestContent?jobId=${jobId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(combinedData),
          }
        );
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        const result = res.status;
        log("Thrid step salesforce status received", result);
        sessionStorage.removeItem("initiatedJobId"); //Since job is in progress, we are clearing the initiatedJobId
        closeModal();
      } catch (error) {
        console.error("Failed to send data to API:", error);
      } finally {
        await timeout(1000);
        setLoading(false);
        closeModal();
      }
    }
  };
  return (
    <section className="stepThreeserviceNow">
      {loading ? (
        <div
          className="loaderContainer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div className="stepThreeserviceNowConnectorsHeader">
            <div className="stepThreeserviceNowConnectorsContent">
              <h3>Set Document Attributes</h3>
              <p>
                Select document attributes applicable to the documents. These
                will be used for filtering based on user entitlements. Note that
                the attributes selected below are applicable across all
                documents selected for ingestion.
                <br />
                If you want to add new attributes(other than those displayed
                under "User Properties" section of the dashboard), please ensure
                that bot is in edit mode.
              </p>
            </div>
            <div className="serviceNowLogo">
              <img src={SalesForceLogo} alt="ServiceNow" />
            </div>
          </div>
          <div className="stepThreeserviceNowDocPropertiesHeading">
            <h4>Choose from the following properties</h4>
          </div>
          <div className="stepThreeserviceNowDocPropertiesSetting">
            <DropDownComponent
              ref={formRef}
              initialOptions={initialUserProperties}
            />
          </div>
          <div className="stepThreeServiceNowButtons">
            <Button onClick={onBack}>Back</Button>
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </>
      )}
    </section>
  );
};
const SalesForceConnectors = ({ onBackToOptions, updateSubStep, closeModal }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [articleTypes, setArticleTypes] = useState([]);
  const[salesForceData, setSalesForceData] = useState(0);
  const [initialUserProperties, setInitialUserProperties] = useState(null);
// , jobId, setSalesForceData, setInitialUserProperties
  const [jobId, setJobId] = useState(0);
  const nextStep = () => {
    const newStep = currentStep + 1;
    if (currentStep < 2) {
      setCurrentStep(newStep);
      updateSubStep(newStep);
    }
  };
  const prevStep = () => {
    const newStep = currentStep - 1;
    if (newStep >= 0) {
      setCurrentStep(newStep);
      updateSubStep(newStep);
    } else {
      onBackToOptions();
    }
  };
  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <StepOneSalesForce
            onNext={nextStep}
            onBack={prevStep}
            onBackToOptions={onBackToOptions}
            setJobId={setJobId}
            setArticleTypes={setArticleTypes}
          />
        );
      case 1:
        return <StepTwoSalesForce onNext={nextStep} onBack={prevStep} jobId={jobId} articleTypes={articleTypes} setSalesForceData={setSalesForceData} setInitialUserProperties={setInitialUserProperties}/>;
      case 2:
        return <StepThreeSalesForce onNext={nextStep} onBack={prevStep} jobId={jobId} closeModal={closeModal} salesForceData={salesForceData} initialUserProperties={initialUserProperties}/>;
    }
  };
  return <>{renderStep()}</>;
};

export default SalesForceConnectors;
