import React, { useState, useEffect, useCallback } from "react";
import { Switch, Input } from "antd";
import AnswerConfigComponent from "../AnswerConfigComponent";
import "../../styles/WebsiteConnectorOptions/siteMapOption.css";
const SiteMapOption = ({ setFormDataAndValidity }) => {
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [siteMapData, setSiteMapData] = useState({
    type: "web",
    sorConfig: {
      sourceUrl: "",
      userName: "",
      password: "",
      webtype: "sitemap",
    },
    answersDetails: {
      documentGroupId: "",
      apiServer: "",
      accessToken: "",
    },
  });

  const handleSwitchChange = (checked) => {
    setIsSwitchChecked(checked);
  };
  const handleChange = (event, section) => {
    const { name, value } = event.target;
    setSiteMapData((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [name]: value,
      },
    }));
  };
  const updateAnswersDetails = useCallback((details) => {
    setSiteMapData((prevFormData) => ({
      ...prevFormData,
      answersDetails: { ...details },
    }));
  }, []);
  useEffect(() => {
    const isValid = isSwitchChecked
      ? Boolean(
          siteMapData.sorConfig.sourceUrl &&
            siteMapData.sorConfig.userName &&
            siteMapData.sorConfig.password
        )
      : Boolean(siteMapData.sorConfig.sourceUrl);
    setFormDataAndValidity("1", { ...siteMapData }, isValid);
  }, [siteMapData, isSwitchChecked]);
  return (
    <div className="webSiteConnectorsInput">
      <div className="webSiteConnectorsInput1">
        <h4>Source URL</h4>
        <Input
          placeholder="Enter Source URL"
          name="sourceUrl"
          value={siteMapData.sourceUrl}
          onChange={(e) => handleChange(e, "sorConfig")}
        />
      </div>
      <div className="websiteConnectorsAuth">
        <h4>Required Auth</h4>
        <div className="websiteConnectorSwitchContent">
          <span className="websiteConnectorSwitch">
            {isSwitchChecked ? "Yes" : "No"}
          </span>
          <Switch checked={isSwitchChecked} onChange={handleSwitchChange} />
        </div>
      </div>
      {isSwitchChecked && (
        <div className="webSiteConnectorsInput2">
          <div className="webSiteUserName">
            <h4>User Name</h4>
            <Input
              placeholder="Enter User Name"
              name="userName"
              value={siteMapData.userName}
              onChange={(e) => handleChange(e, "sorConfig")}
            />
          </div>
          <div className="webSitePassword">
            <h4>Password</h4>
            <Input
              placeholder="Enter Password"
              name="password"
              value={siteMapData.password}
              onChange={(e) => handleChange(e, "sorConfig")}
            />
          </div>
        </div>
      )}
      <AnswerConfigComponent updateAnswersDetails={updateAnswersDetails} />
    </div>
  );
};

export default SiteMapOption;
