import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { useAuth } from "../AuthContext";
import "../styles/answersConfigComponent.css";
import log from "./logger";

function AnswerConfigComponent({ documentGroupId, updateAnswersDetails }) {
  const { tokenDetails } = useAuth();
  const instanceMatch = tokenDetails.instance.match(/https:\/\/(c\d+)\./);
  const apiServer = instanceMatch ? instanceMatch[1] : "";
  const [localFormData, setLocalFormData] = useState({
    documentGroupId: documentGroupId,
    apiServer: apiServer || "",
    accessToken: tokenDetails.user.access_token || "",
  });
  useEffect(() => {
    updateAnswersDetails(localFormData);
  }, [localFormData, updateAnswersDetails]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setLocalFormData({ ...localFormData, [name]: value });
  };
  return (
    <div className="answerConfigComponent">
      <div className="answerConfigInput">
        <div className="answerDocumentGroupId">
          <h4>Document Group ID<span style={{ color: 'red' }}>*</span></h4>
          <Input
            placeholder="Enter Document ID"
            name="documentGroupId"
            value={localFormData.documentGroupId}
            onChange={handleChange}
          />
        </div>
        <div className="answerAPIServer">
          <h4>API Server</h4>
          <Input
            placeholder="Enter API Server"
            name="apiServer"
            value={localFormData.apiServer}
            onChange={handleChange}
            disabled
          />
        </div>
      </div>
      <div className="answersAccessToken">
        <h4>Access Token</h4>
        <Input
          placeholder="Enter Access Token"
          name="accessToken"
          value={localFormData.accessToken}
          onChange={handleChange}
          disabled
        />
      </div>
    </div>
  );
}

export default AnswerConfigComponent;
