import React, { useState } from "react";
import { Modal } from "antd";
import ContentIngestionProgressBar from "./ContentIngestionProgressBar";
import AddFileContent from "./AddFileContent";
import SharePointConnectors from "./SharePointConnectors";
import ServiceNowConnectors from "./ServiceNowConnectors";
import SalesForceConnectors from "./SalesForceConnectors";
import WebsiteConnector from "./WebsiteConnector";
import FilesIcon from "../assets/ContentIngestionTypes/files-icn.svg";
import SharePoint from "../assets/ContentIngestionTypes/sharepoint-icn.svg";
import SalesForce from "../assets/ContentIngestionTypes/salesforce-icn.svg";
import ServiceNow from "../assets/ContentIngestionTypes/servicenow-icn.svg";
import AzureData from "../assets/ContentIngestionTypes/azure-icn.svg";
import MySql from "../assets/ContentIngestionTypes/mysql-icn.svg";
import WebSiteLofo from "../assets/ContentIngestionTypes/website-icn.svg";
import ContentIngestionType from "./ContentIngestionType";
import { useAuth } from "../AuthContext";
import log from "./logger";

import "../styles/addNewContentModal.css";
const AddNewContentModal = ({ onContentSourceAdded, editable }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSourceType, setSelectedSourceType] = useState("");
  const [currentMainStep, setCurrentMainStep] = useState(0);
  const [currentSubStep, setCurrentSubStep] = useState(0);
  const { tokenDetails } = useAuth();

  const handleSelectSourceType = (sourceType) => {
    setSelectedSourceType(sourceType);
    setCurrentMainStep(1);
    setCurrentSubStep(0);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const deleteStrayJob = async () => {
    try {
      //get jobId from session storage
      const jobId = sessionStorage.getItem("initiatedJobId");
      if (jobId) {
        log("Deleting stray job with jobId:", jobId);
        // call deleteJob API
        const url = `${process.env.REACT_APP_API_URL}/deleteJob?jobId=${jobId}`;
        const response = await fetch(url, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(
            "Network response was not ok, could not delete stray job"
          );
        }
        sessionStorage.removeItem("initiatedJobId");
        window.location.reload();
      }
    } catch (error) {
      console.error("Failed to delete stray job:", error);
    }
  };

  const handleClose = () => {
    //delete stray job if any
    deleteStrayJob();
    setIsModalOpen(false);
    setSelectedSourceType("");
    setCurrentMainStep(0);
    setCurrentSubStep(0);
    sessionStorage.removeItem('formData'); // Deletes prviously filled formData
    if (onContentSourceAdded) {
      onContentSourceAdded();
    }
  };
  const handleBackToOptions = async () => {
    sessionStorage.removeItem('formData'); // Deletes prviously filled formData
    setCurrentMainStep(0);
  };

  const updateSubStep = (subStep) => {
    setCurrentSubStep(subStep);
  };
  const selectedComponent = () => {
    switch (selectedSourceType) {
      case "Files":
        return (
          <AddFileContent
            onBackToOptions={handleBackToOptions}
            updateSubStep={updateSubStep}
          />
        );
      case "SharePoint":
        return (
          <SharePointConnectors
            onBackToOptions={handleBackToOptions}
            updateSubStep={updateSubStep}
            closeModal={handleClose}
          />
        );
      case "ServiceNow":
        return (
          <ServiceNowConnectors
            onBackToOptions={handleBackToOptions}
            updateSubStep={updateSubStep}
            closeModal={handleClose}
          />
        );
      case "Salesforce":
        return (
          <SalesForceConnectors
            onBackToOptions={handleBackToOptions}
            updateSubStep={updateSubStep}
            closeModal={handleClose}
          />
        );
      case "Website":
        return (
          <WebsiteConnector
            onBackToOptions={handleBackToOptions}
            updateSubStep={updateSubStep}
            closeModal={handleClose}
          />
        );
      default:
        return null;
    }
  };

  const renderFooter = () => {
    return null;
  };

  const modalTitle = (
    <>
      <h3>Add New Content Source</h3>
      <button className="modal-close-button" onClick={handleClose}>
        Cancel
      </button>
    </>
  );
  return (
    <section>
      <div className="addnewcontentmodalbutton">
        <button
          className={editable ? "enabled-button" : "disabled-button"}
          onClick={showModal}
          disabled={!editable}
        >
          Add New Content Source
        </button>
      </div>
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onCancel={handleClose}
        width="80%"
        height="800"
        className="customModal"
        style={{ top: 16 }}
        footer={renderFooter()}
        closeIcon={false}
        getContainer={false}
      >
        <div className="addNewContentModal">
          <div className="addNewContentModalHeading">
            <ContentIngestionProgressBar
              currentMainStep={currentMainStep}
              currentSubStep={currentSubStep}
              sourceType={selectedSourceType}
            />
          </div>
          {currentMainStep === 0 && (
            <div className="contentSourceGrid">
              <div className="contentSourceGridHeading">
                <h4>Content Source Type</h4>
                <p>
                  Streamline your content source selection process by seamlessly
                  integrating data from Salesforce, ServiceNow, Azure Data Lake,
                  MySQL, SharePoint, or uploaded files
                </p>
              </div>
              <div className="contentSourceMenu">
                <div className="contentSourceMenu1">
                  <ContentIngestionType
                    name={"Sharepoint"}
                    image={SharePoint}
                    onClick={() => handleSelectSourceType("SharePoint")}
                  />
                  <ContentIngestionType
                    name={"ServiceNow"}
                    image={ServiceNow}
                    onClick={() => handleSelectSourceType("ServiceNow")}
                  />
                  <ContentIngestionType
                    name={"Salesforce"}
                    image={SalesForce}
                    // className="contentSourceDisabled"
                    // onClick={() => handleSelectSourceType("Salesforce")}
                  />
                </div>
                <div className="contentSourceMenu2">
                  <ContentIngestionType
                    name={"Website"}
                    image={WebSiteLofo}
                    // className="contentSourceDisabled"
                    // onClick={() => handleSelectSourceType("Website")}
                  />
                  <ContentIngestionType
                    name={"Azure Datalake"}
                    image={AzureData}
                    // className="contentSourceDisabled"
                    // onClick={() => handleSelectSourceType("ServiceNow")}
                  />
                  <ContentIngestionType
                    name={"Files"}
                    image={FilesIcon}
                    // className="contentSourceDisabled"
                    // onClick={() => handleSelectSourceType("Files")}
                  />
                </div>
                <div className="contentSourceMenu3">
                  <ContentIngestionType
                    name={"MySQL"}
                    image={MySql}
                    // className="contentSourceDisabled"
                    // onClick={() => handleSelectSourceType("MySQL")}
                  />
                </div>
              </div>
            </div>
          )}
          {currentMainStep > 0 && selectedComponent()}
        </div>
      </Modal>
    </section>
  );
};
export default AddNewContentModal;
