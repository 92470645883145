import React, { useState } from "react";
import { Dropdown, Menu, Space, Modal } from "antd";
import { HiDotsVertical } from "react-icons/hi";
import { FiRefreshCcw, FiSettings } from "react-icons/fi";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { LuCopy } from "react-icons/lu";
import "../styles/kebabMenu.css";
import log from "./logger";

function KebabMenu({ jobId, type }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isJobDetailsModalVisible, setIsJobDetailsModalVisible] =
    useState(false);
  const [jobDetails, setJobDetails] = useState(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const fetchJobDetails = async () => {
    log("logging jobdetails");
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/getJobDetails?jobId=${jobId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const data = await res.json();
      setJobDetails(data.result[0]);
      log(data.result[0]);
      setIsJobDetailsModalVisible(true);
    } catch (error) {
      console.error("There was an error fetching the job details:", error);
    }
  };
  const menu = (
    <Menu
      className="kebabMenuItems"
      onClick={({ key }) => {
        switch (key) {
          case "0":
            showModal();
            break;
          case "1":
            fetchJobDetails();
            log("in job details");
            break;
          default:
            break;
        }
      }}
      items={[
        {
          label: (
            <Space>
              <div className="kebabMenuItemsImage">
                <FiRefreshCcw
                  style={{ color: "#858795", height: "18", width: "20" }}
                />
              </div>
              Setup Content Sync
            </Space>
          ),
          key: "0",
        },
        {
          label: (
            <Space>
              <div className="kebabMenuItemsImage">
                <FiSettings
                  style={{ color: "#858795", height: "18", width: "20" }}
                />
              </div>
              View Job details
            </Space>
          ),
          key: "1",
        },
      ]}
    />
  );
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const handleClose = () => {
    setIsModalVisible(false);
    setIsJobDetailsModalVisible(false);
  };
  const renderFooter = () => {
    return null;
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const modalTitle = (
    <>
      <h3>Setup Content Sync</h3>
      <button className="modal-close-button" onClick={handleClose}>
        Close
      </button>
    </>
  );
  const jobDetailsModalTitle = (
    <>
      <h3>Job Details</h3>
      <button className="modal-close-button" onClick={handleClose}>
        Close
      </button>
    </>
  );

  const businessRuleCodeSnippet = `/*
Configuration for setting up business rules - 
Table - kb_knowledge
When - after
For - Insert, Update
*/

(function executeRule(current, previous /*null when async*/) {
var article_number = current.number;
var sys_id = current.sys_id;
var url = "${process.env.REACT_APP_API_URL}/servicenow/webhook/${jobId}?sys_id=" + sys_id + "&number=" + article_number;

var message = {
   "user": {
       "first_name": "ServiceNow",
       "last_name": "Business Rule",
       "uuid": "19ac15843-151d-47fb-8b3d-930b89ce712g",
       "custom_properties": {}
   },
   "status": "update",
   "document":"kb"
};
if (current.retired || current.workflow_state == "retired" || current.archived || current.workflow_state == "pending_retirement" || current.workflow_state == "archived") {
   message.status = "delete";
}

var api_call = new sn_ws.RESTMessageV2();
api_call.setHttpMethod('post');
api_call.setEndpoint(url);
api_call.setRequestHeader("content-type", "application/json");
api_call.setRequestBody(JSON.stringify(message));


var response = api_call.execute();
var http_response_status = response.getStatusCode();

gs.info(http_response_status);
})(current, previous);
`;

//   const businessRuleDeletingCodeSnippet = `/*
// Configuration for setting up business rules - 
// Table - kb_knowledge
// When - before
// For - Delete
// */

// (function executeRule(current, previous /*null when async*/) {
// var article_number = current.number;

// var url = "${process.env.REACT_APP_API_URL}/servicenow/webhook/${jobId}?sys_id=" + sys_id + "&number=" + article_number;


// var message = {
// "user": {
// "first_name": "ServiceNow",
// "last_name": "Business Rule",
// "uuid": "19ac15843-151d-47fb-8b3d-930b89ce712g",
// "custom_properties": {}
// },
// "status": "delete"
// };


// var api_call = new sn_ws.RESTMessageV2();
// api_call.setHttpMethod('post');
// api_call.setEndpoint(url);
// api_call.setRequestHeader("content-type", "application/json");
// api_call.setRequestBody(JSON.stringify(message));


// var response = api_call.execute();
// var http_response_status = response.getStatusCode();


// gs.info(http_response_status);
// })(current, previous);
// `;

const businessRuleSharePointSinppet = `curl -X POST \
  ${process.env.REACT_APP_API_URL}/sharepoint/webhook/${jobId} \
-H 'content-type: application/json' \
-d '{
"period": "604800",
"includeNewContent": true
}'
`;

  const businessRuleAttachmentCodeSnippet = `/*
Configuration for setting up business rules - 
Table - sys_attachment
When - after
For - Insert, Update, Delete
Order - 10
*/
(function executeRule(current, previous /*null when async*/) {
  // Check if there's an update in the sys_attachment table
  if (current && (current.operation() === 'insert' || current.operation() === 'delete')){
    // Query the kb_knowledge table to retrieve additional information
    var kbKnowledge = new GlideRecord('kb_knowledge');
    
    if (kbKnowledge.get(current.table_sys_id)) {
      // If the KB article is found, retrieve the KB number
      var kbNumber = kbKnowledge.getValue('number');
      var sysId = current.getUniqueValue();
      var articleSysId= current.table_sys_id;

      var url = "${process.env.REACT_APP_API_URL}/servicenow/webhook/${jobId}?sys_id=" + sysId + "&number=" + kbNumber + "&article_sys_id=" + articleSysId;
      var message = {
        "user": {
        "first_name": "ServiceNow",
        "last_name": "Business Rule",
        "uuid": "19ac15843-151d-47fb-8b3d-930b89ce712g",
        "custom_properties": {}
        },
        "document":"attachment",
        "status": current.operation(),
        };
      var api_call = new sn_ws.RESTMessageV2();
      api_call.setHttpMethod('post');
      api_call.setEndpoint(url);
      api_call.setRequestHeader("content-type", "application/json");
      api_call.setRequestBody(JSON.stringify(message));

      var response = api_call.execute();
      var http_response_status = response.getStatusCode();
      gs.info('Attachment updated. Operation: ' + current.operation() + ', sys_id: ' + current.getUniqueValue() + ', KB Number: ' + kbNumber );
    }
 
  }
})(current, previous);
`;
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      alert("Copied to clipboard");
    } catch (error) {
      console.error("Failed to copy: ", error);
      alert("Failed to copy text");
    }
  };
  const readableDate = new Date(jobDetails?.createdAt).toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    }
  );

  const readableTime = new Date(jobDetails?.createdAt).toLocaleTimeString(
    "en-US",
    {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }
  );
  // const handleScroll = (e) => {
  //   const scrollContainer = e.target;
  //   const button = scrollContainer.querySelector(".copyButton");
  //   button.style.top = `${scrollContainer.scrollTop}px`;
  // };
  return (
    <>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        className="kebabMenu customDropdown"
        overlayStyle={{ inset: "18px auto auto 380px", width: "240px" }}
      >
        <a onClick={(e) => e.preventDefault()}>
          <HiDotsVertical style={{ cursor: "pointer", color: "#858795" }} />
        </a>
      </Dropdown>
      <Modal
        title={modalTitle}
        open={isModalVisible}
        onCancel={handleClose}
        width="80%"
        height="800"
        className="kebabMenuModal"
        footer={renderFooter()}
        closeIcon={false}
      >
        {type === "servicenow" && (
          <section className="KebabMenuModalSection">
            <div className="KebabMenuModalHeading">
              <h4>Preview Articles</h4>
              <p>
                Configure a Business Rule for your ServiceNow Instance with the
                following details to enable real time updates to this content
                source.
              </p>
            </div>
            <div className="KebabMenuModalBusinessRuleUpdating">
              <h5>Business Rule for updating article</h5>
              <div className="KebabMenuModalBusinessRuleCode">
                <pre>
                  <code>{businessRuleCodeSnippet}</code>
                </pre>
                {/* <button
                  onClick={() => copyToClipboard(businessRuleCodeSnippet)}
                  className="copyButton"
                  aria-label="Copy to clipboard"
                >
                  <LuCopy />
                </button> */}
              </div>
            </div>
            {/* <div className="KebabMenuModalBusinessRuleUpdating">
              <h5>Business Rule for deleting article</h5>
              <div className="KebabMenuModalBusinessRuleCode">
                <pre>
                  <code>{businessRuleDeletingCodeSnippet}</code>
                </pre>
                <button
                  onClick={() =>
                    copyToClipboard(businessRuleDeletingCodeSnippet)
                  }
                  className="copyButton"
                  aria-label="Copy to clipboard"
                >
                  <LuCopy />
                </button>
              </div>
            </div> */}
            <div className="KebabMenuModalBusinessRuleUpdating">
              <h5>Business Rule for syncing attachments</h5>
              <div className="KebabMenuModalBusinessRuleCode">
                <pre>
                  <code>{businessRuleAttachmentCodeSnippet}</code>
                </pre>
                {/* <button
                  onClick={() =>
                    copyToClipboard(businessRuleAttachmentCodeSnippet)
                  }
                  className="copyButton"
                  aria-label="Copy to clipboard"
                >
                  <LuCopy />
                </button> */}
              </div>
            </div>
          </section>
        )}
        {type === "sharepoint" && (
          <section className="KebabMenuModalSection">
            <div className="KebabMenuModalHeading">
              <h4>Preview Articles</h4>
              <p>
                To enable content sync, create a cron job and upon a stipulated
                frequency, invoke the below API. The following parameters can be
                passed -
              </p>
              <ul>
                <li>
                  <b>period</b> (string) - Frequency of the cron job in seconds
                </li>
                <li>
                  <b>includeNewContent</b> (bool) - If true, the connector
                  ingests every new artifact coming into the selected sites.
                </li>
              </ul>
            </div>
            <div className="KebabMenuModalBusinessSharepointCode">
              <pre>
                <code>{businessRuleSharePointSinppet}</code>
              </pre>
              {/* <button
                onClick={() => copyToClipboard(businessRuleSharePointSinppet)}
                className="copyButton"
                aria-label="Copy to clipboard"
              >
                <LuCopy />
              </button> */}
            </div>
          </section>
        )}
      </Modal>
      <Modal
        title={jobDetailsModalTitle}
        open={isJobDetailsModalVisible}
        onCancel={handleClose}
        width="80%"
        className="jobDetailsModal"
        footer={null}
        closeIcon={false}
      >
        <section className="JobDetailsModalSection">
          <div className="JobDetailsModalBasicInfo">
            <div className="jobDetailsDiv">
              <div className="jobDetailsp">
                <p>
                  <strong>Job ID</strong>
                </p>
              </div>
              <div className="jobDetailsValue">{jobDetails?.id}</div>
            </div>
            <div className="jobDetailsDiv">
              <div className="jobDetailsp">
                <p>
                  <strong>Initiated By</strong>
                </p>
              </div>
              <div className="jobDetailsValue">
                {jobDetails
                  ? `${jobDetails.first_name} ${jobDetails.last_name}`
                  : "NA"}
              </div>
            </div>
            <div className="jobDetailsDiv">
              <div className="jobDetailsp">
                <p>
                  <strong>Document Group</strong>
                </p>
              </div>
              <div className="jobDetailsValue">
                {jobDetails?.article_doc_group}
              </div>
            </div>
            <div className="jobDetailsDiv">
              <div className="jobDetailsp">
                <p>
                  <strong>Initiated at</strong>
                </p>
              </div>
              <div className="jobDetailsValue">
                {readableDate} at {readableTime}
              </div>
            </div>
          </div>
          {jobDetails?.type === "servicenow" && (
            <div className="JobDetailsModalTypeInfo">
              <div className="jobDetailsDiv">
                <div className="jobDetailsp">
                  <p>
                    <strong>User Name</strong>
                  </p>
                </div>
                <div className="jobDetailsValue">{jobDetails?.username}</div>
              </div>

              <div className="jobDetailsDiv">
                <div className="jobDetailsp">
                  <p>
                    <strong>Password</strong>
                  </p>
                </div>
                <div className="jobDetailsValue">
                  {isPasswordVisible ? jobDetails?.password : "••••••••"}
                  <button
                    onClick={togglePasswordVisibility}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    {isPasswordVisible ? (
                      <AiOutlineEyeInvisible />
                    ) : (
                      <AiOutlineEye />
                    )}
                  </button>
                </div>
              </div>
              <div className="jobDetailsDiv">
                <div className="jobDetailsp">
                  <p>
                    <strong>Source Name</strong>
                  </p>
                </div>
                <div className="jobDetailsValue">{jobDetails?.source_name}</div>
              </div>
              <div className="jobDetailsDivUrl">
                <div className="jobDetailsp">
                  <p>
                    <strong>Servicenow Query URL</strong>
                  </p>
                </div>
                <div className="jobDetailsValueUrl">{jobDetails?.url}</div>
              </div>
              <div className="jobDetailsDiv">
                <div className="jobDetailsp">
                  <p>
                    <strong>Access Token</strong>
                  </p>
                </div>
                <div className="jobDetailsValue">
                  {jobDetails?.access_token}
                </div>
              </div>
            </div>
          )}
          {jobDetails?.type === "sharepoint" && (
            <div className="JobDetailsModalTypeInfo">
              <div className="jobDetailsDiv">
                <div className="jobDetailsp">
                  <p>
                    <strong>Client ID</strong>
                  </p>
                </div>
                <div className="jobDetailsValue">{jobDetails?.client_id}</div>
              </div>
              <div className="jobDetailsDiv">
                <div className="jobDetailsp">
                  <p>
                    <strong>Client Secret</strong>
                  </p>
                </div>
                <div className="jobDetailsValue">
                  {isPasswordVisible ? jobDetails?.client_secret : "••••••••"}
                  <button
                    onClick={togglePasswordVisibility}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    {isPasswordVisible ? (
                      <AiOutlineEyeInvisible />
                    ) : (
                      <AiOutlineEye />
                    )}
                  </button>
                </div>
              </div>
              <div className="jobDetailsDiv">
                <div className="jobDetailsp">
                  <p>
                    <strong>Tenant</strong>
                  </p>
                </div>
                <div className="jobDetailsValue">{jobDetails?.tenant}</div>
              </div>
              <div className="jobDetailsDiv">
                <div className="jobDetailsp">
                  <p>
                    <strong>Tenant ID</strong>
                  </p>
                </div>
                <div className="jobDetailsValue">{jobDetails?.tenant_id}</div>
              </div>
            </div>
          )}
        </section>
      </Modal>
    </>
  );
}

export default KebabMenu;
