import React, { useState, useCallback } from "react";
import { Button, Input, Table } from "antd";
import FilterLogo from "../../assets/ContentSources/filter.svg";
import FilesLogo from "../../assets/ContentIngestionTypes/files-icn.svg";
import PdfIcon from "../../assets/ContentSources/pdf.svg";
import DocxIcon from "../../assets/ContentSources/docx.svg";
import "../../styles/ContentSourcesComponentsStyles/localFilesComponentStyles.css";
import debounce from "lodash.debounce";
import log  from "../logger";

import { SearchOutlined } from "@ant-design/icons";
const LocalFilesComponents = () => {
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const fetchFilteredData = useCallback(
    debounce((query) => {
      setLoading(true);
      // Perform your search logic here...
      log("Data enters>>>", query);
      setLoading(false);
    }, 1000),
    []
  );
  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchText(value);
    fetchFilteredData(value);
  };
  const getFileIcon = (format) => {
    switch (format) {
      case "PDF":
        return <img src={PdfIcon} alt="PDF" style={{ marginRight: 6 }} />;
      case "DOCX":
        return <img src={DocxIcon} alt="DOCX" style={{ marginRight: 6 }} />;
      // Add cases for other formats and their icons
      default:
        return null;
    }
  };
  const columns = [
    {
      title: "SOURCE NAME",
      dataIndex: "name",
      key: "name",
      width: "60%",
      render: (text, record) => (
        <span className="centered-content">
          {getFileIcon(record.format)}
          {text}
        </span>
      ),
    },
    {
      title: "FORMAT",
      dataIndex: "format",
      width: "10%",
      key: "format",
    },
    {
      title: "SIZE",
      dataIndex: "size",
      width: "10%",
      key: "size",
    },
    {
      title: "DATE ADDED",
      dataIndex: "dateadded",
      width: "40%",
      key: "dateadded",
    },
  ];
  const dummyData = [
    {
      key: "1",
      name: "Global_Business_IT_PolicyOct224.api.pdf",
      format: "PDF",
      size: "304 KB",
      dateadded: "1st Oct 2023 10:30 AM",
    },
    {
      key: "2",
      name: "HR14069_IT_Policy_document_1_3.pdf",
      format: "PDF",
      size: "1 MB",
      dateadded: "2nd Oct 2023 12:30 AM",
    },
    {
      key: "3",
      name: "Password Policy.docx",
      format: "DOCX",
      size: "500 KB",
      dateadded: "30th Sep 2023 2:30 PM",
    },
    {
      key: "4",
      name: "Global_Business_IT_PolicyOct224.api.pdf",
      format: "PDF",
      size: "304 KB",
      dateadded: "1st Oct 2023 10:30 AM",
    },
    {
      key: "5",
      name: "HR14069_IT_Policy_document_1_3.pdf",
      format: "PDF",
      size: "1 MB",
      dateadded: "2nd Oct 2023 12:30 AM",
    },
    {
      key: "6",
      name: "Password Policy.docx",
      format: "DOCX",
      size: "500 KB",
      dateadded: "30th Sep 2023 2:30 PM",
    },
    {
      key: "7",
      name: "Global_Business_IT_PolicyOct224.api.pdf",
      format: "PDF",
      size: "304 KB",
      dateadded: "1st Oct 2023 10:30 AM",
    },
    {
      key: "8",
      name: "HR14069_IT_Policy_document_1_3.pdf",
      format: "PDF",
      size: "1 MB",
      dateadded: "2nd Oct 2023 12:30 AM",
    },
    {
      key: "9",
      name: "Password Policy.docx",
      format: "DOCX",
      size: "500 KB",
      dateadded: "30th Sep 2023 2:30 PM",
    },
    {
      key: "10",
      name: "Global_Business_IT_PolicyOct224.api.pdf",
      format: "PDF",
      size: "304 KB",
      dateadded: "1st Oct 2023 10:30 AM",
    },
    {
      key: "11",
      name: "HR14069_IT_Policy_document_1_3.pdf",
      format: "PDF",
      size: "1 MB",
      dateadded: "2nd Oct 2023 12:30 AM",
    },
    {
      key: "12",
      name: "Password Policy.docx",
      format: "DOCX",
      size: "500 KB",
      dateadded: "30th Sep 2023 2:30 PM",
    },
  ];
  return (
    <section className="localFilesComponent">
      <div className="loaclFilesComponentHeader">
        <div className="localFilesComponentSubHeadder">
          <img src={FilesLogo} alt="Files" />
          <h4>Acme Files</h4>
        </div>
        <Button>Upload</Button>
      </div>
      <div className="localFilesComponentSearch">
        <div className="localFilesComponentSearchHeader">
          <img src={FilterLogo} alt="Filter" />
          <h5>Uploaded Files</h5>
        </div>
        <div className="localFilesComponentSubSearch">
          <span className="outerSpan">
            Total Files <span className="innerSpan">10</span>
          </span>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={handleSearchInputChange}
            onPressEnter={() => fetchFilteredData(searchText)}
            style={{ width: 200, margin: "0 0" }}
            loading={loading}
            allowClear
          />
        </div>
      </div>
      <div className="localFilesComponentTableContent">
        <Table
          columns={columns}
          dataSource={dummyData}
          pagination={{ pageSize: 10 }}
          rowKey="key"
          loading={loading}
        />
      </div>
    </section>
  );
};

export default LocalFilesComponents;
