import React, { createContext, useContext, useState } from "react";
const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [tokenDetails, setTokenDetails] = useState(null);

  const setToken = (tokenData) => {
    setTokenDetails(tokenData);
  };

  return (
    <AuthContext.Provider value={{ tokenDetails, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};
