import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../AuthContext";
import Loader from "../components/Loader";
import AddNewContentModal from "../components/AddContent";
import AddedContent from "../components/AddedContent";
import LocalFilesComponents from "../components/ContentSourcesComponents/LocalFilesComponents";
import SalesForceComponent from "../components/ContentSourcesComponents/SalesForceComponent";
import SharePointComponent from "../components/ContentSourcesComponents/SharePointComponent";
import WebsiteComponent from "../components/ContentSourcesComponents/WebsiteComponent";
import ServiceNowComponent from "../components/ContentSourcesComponents/ServiceNowComponent";
import SalesForce from "../assets/salesForce.svg";
import Azure from "../assets/azure.svg";
import SharePoint from "../assets/sharePoint.svg";
import ServiceNow from "../assets/serviceNow.svg";
import MySQL from "../assets/sql.svg";
import FilesLogo from "../assets/ContentIngestionTypes/files-icn.svg";
import ServiceNowLogo from "../assets/ContentIngestionTypes/servicenow-icn.svg";
import SharePointLogo from "../assets/ContentIngestionTypes/sharepoint-icn.svg";
import SalesForceLogo from "../assets/ContentIngestionTypes/salesforce-icn.svg";
import WebsiteLogo from "../assets/ContentIngestionTypes/website-icn.svg";
import "../styles/addContentSourcesAddition.css";
import log from "../components/logger";
function ContentSourcesAddition() {
  const [selectedItem, setSelectedItem] = useState({
    id: null,
    sourceName: "",
    type: "",
  });
  // log("logging the env file", process.env.REACT_APP_API_URL);
  // let [searchParams] = useSearchParams();
  const [editable, setEditable] = useState(false);
  const [contentSources, setContentSources] = useState([]);
  const [percentageJobDone, setPercentageJobDone] = useState(0);
  const [jobDetails, setJobDetails] = useState(null);
  const { setToken, tokenDetails } = useAuth();
  const intervalIdRef = useRef(null);
  const POLLING_DELAY = 10000;
  const PROGRESS_COMPLETE_THRESHOLD = 2;
  // useEffect(() => {
  //   log("Getting the JWT >>>>");
  //   let newToken = {
  //     agent_id: "",
  //     company_id: "",
  //     exp: "",
  //     instance: "",
  //     permission: "",
  //     user: {
  //       access_token: "",
  //       first_name: "",
  //       id: "",
  //       last_name: "",
  //     },
  //   };
  //   let instancePrefix = "";
  //   for (const [key, value] of searchParams.entries()) {
  //     log("search param entry>>", key, value);
  //     if (key === "instance") {
  //       newToken.instance = value;
  //       const prefixMatch = value.match(/(?<=https:\/\/)([^.]+)/);
  //       if (prefixMatch) {
  //         instancePrefix = prefixMatch[0];
  //       }
  //     } else {
  //       const parts = key.match(/(?<=\[).+?(?=\])/g);
  //       if (parts) {
  //         let currentPart = newToken;
  //         parts.forEach((part, index) => {
  //           if (index === parts.length - 1) {
  //             currentPart[part] = value;
  //           } else {
  //             currentPart[part] = currentPart[part] || {};
  //             currentPart = currentPart[part];
  //           }
  //         });
  //       } else if (key === "agent_id") {
  //         newToken.agent_id = instancePrefix
  //           ? `${instancePrefix}_${value}`
  //           : value;
  //       } else {
  //         console.error(`Unexpected parameter format: ${key}`);
  //       }
  //     }
  //   }
  //   if (newToken.agent_id && !newToken.agent_id.startsWith(instancePrefix)) {
  //     newToken.agent_id = `${instancePrefix}_${newToken.agent_id}`;
  //   }
  //   log("setting context token>>", newToken);
  //   setToken(newToken);
  // }, [searchParams]);

  useEffect(()=>{
    try{
      //split by ? and send all query params
      let params = window.location.href.split('?')[1];
      // log("params>", params);

      const decode = async () =>{
        const url = `${process.env.REACT_APP_API_URL}/decodeToken?${params}`;
        let response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        response = await response.json();
        log("decodedResponse>", response);
        let instancePrefix = "";
        const prefixMatch = response.instance.match(/(?<=https:\/\/)([^.]+)/);
        if (prefixMatch) {
          instancePrefix = prefixMatch[0];
        }
        response.agent_id = `${instancePrefix}_${response.agent_id}`;
        log("setting context token>>", response);
        setToken(response);
      }
      decode();

    }catch(err){
      log("Failed to decode token>", err);
    }
  }, []);
  const allowNewIngestion = async () => {
    try {
      log("tokenDetails in allowNewIngestion", tokenDetails);
      if (tokenDetails) {
        const url = `${
          process.env.REACT_APP_API_URL
        }/isBotEditable?token=${JSON.stringify(tokenDetails)}`;
        let response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        response = await response.json();
        log("isEditable>>", response.editable);
        setEditable(response.editable);
      } else return true;
    } catch (err) {
      log("Error in allowNewIngestion", err);
      return true;
    }
  };

  const clearPolling = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  useEffect(() => {
    if (tokenDetails && tokenDetails.instance) {
      log("Logging the JWT for reference >>>>", tokenDetails);
      log("Token is set, fetching content sources...");
      fetchContentSources();
      allowNewIngestion();
    }
  }, [tokenDetails]);

  const handleSelect = async (id, sourceName, type) => {
    log("id>>", id, sourceName, type);
    setSelectedItem({ id, sourceName, type });
    let lastIngestedCount = 0;
    let STAGNAT_COUNT = 0;
    clearPolling(); // Clear any existing polling
    const fetchAndUpdateJobData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/status?jobId=${id}&agentId=${tokenDetails.agent_id}`
        );
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        const jobData = await res.json();
        log(jobData);
        log("selectedItem.id", id);
        if (id.toString() === jobData.jobId) {
          log("inside the id block", jobData.jobId);
          if (
            jobData &&
            Array.isArray(jobData.processedArticles) &&
            jobData.numberOfArticles
          ) {
            const ingestedArticlesCount = jobData.processedArticles.filter(
              (article) =>
                article.status !== "queued" && article.status !== "in progress"
            ).length;
            if (ingestedArticlesCount !== lastIngestedCount) {
              STAGNAT_COUNT = 0;
              lastIngestedCount = ingestedArticlesCount;
              log(
                "ingestedArtile count>",
                ingestedArticlesCount,
                jobData.numberOfArticles,
                lastIngestedCount
              );
              const percentageDone =
                (ingestedArticlesCount / jobData.numberOfArticles) * 100;
              log(`Percentage done: ${percentageDone}%`);
              setPercentageJobDone(percentageDone.toFixed(2));
            } else {
              STAGNAT_COUNT = STAGNAT_COUNT + 1;
              if (STAGNAT_COUNT === PROGRESS_COMPLETE_THRESHOLD) {
                log("ready to process unprocessable");
                const res = await fetch(
                  `${process.env.REACT_APP_API_URL}/status?jobId=${id}&agentId=${tokenDetails.agent_id}&unprocessable=true`
                );
                if (!res.ok) {
                  throw new Error("Network response was not ok");
                }
                const jobData = await res.json();
                setJobDetails(jobData);
                clearPolling();
                log("No progress done so far exiting out of the loop");
                return;
              }
            }
            setJobDetails(jobData);
            if (
              jobData.jobStatus === "complete" ||
              jobData.jobStatus === "failed"
            ) {
              setPercentageJobDone(100);
              clearPolling();
              log("Job is complete");
            }
          } else if (!jobData.numberOfArticles) {
            log("ready to process unprocessable");
            const res = await fetch(
              `${process.env.REACT_APP_API_URL}/status?jobId=${id}&agentId=${tokenDetails.agent_id}&unprocessable=true`
            );
            if (!res.ok) {
              throw new Error("Network response was not ok");
            }
            const jobData = await res.json();
            setJobDetails(jobData);
            clearPolling();
            log("No progress done so far exiting out of the loop");
            return;
          }
        }
      } catch (error) {
        console.error("Failed to fetch job data", error);
        clearPolling();
      }
    };
    fetchAndUpdateJobData();
    intervalIdRef.current = setInterval(fetchAndUpdateJobData, POLLING_DELAY);
  };
  const fetchContentSources = async () => {
    try {
      // const token = JSON.stringify(tokenDetails);
      // log("Logging the token insde async block", token);
      // const url = `${process.env.REACT_APP_API_URL}/jobs?instance=${instance}&token=${token}`;
      const url = `${process.env.REACT_APP_API_URL}/jobs?agent_id=${tokenDetails.agent_id}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      log("logging data from jobs api", data);
      setContentSources(data.jobDetails);
      if (data.jobDetails && data.jobDetails.length > 0) {
        const mostRecentJob = data.jobDetails[0];
        handleSelect(
          mostRecentJob.id,
          mostRecentJob.source,
          mostRecentJob.type
        );
      }
    } catch (error) {
      console.error("Failed to fetch content sources:", error);
    }
  };

  const deleteStrayJob = async () => {
    try {
      //get jobId from session storage
      const jobId = sessionStorage.getItem("initiatedJobId");
      if (jobId) {
        log("Deleting stray job with jobId:", jobId);
        // call deleteJob API
        const url = `${process.env.REACT_APP_API_URL}/deleteJob?jobId=${jobId}`;
        const response = await fetch(url, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(
            "Network response was not ok, could not delete stray job"
          );
        }
        sessionStorage.removeItem("initiatedJobId");
        window.location.reload();
      }
    } catch (error) {
      console.error("Failed to delete stray job:", error);
    }
  };

  useEffect(() => {
    fetchContentSources();
    deleteStrayJob();
  }, []);
  const getImage = (type) => {
    switch (type) {
      case "web":
        return WebsiteLogo;
      case "Local Files":
        return FilesLogo;
      case "salesforce":
        return SalesForceLogo;
      case "sharepoint":
        return SharePointLogo;
      case "servicenow":
        return ServiceNowLogo;
    }
  };
  const getSelectedComponent = () => {
    switch (selectedItem.type) {
      case "Local Files":
        return <LocalFilesComponents />;
      case "servicenow":
        return (
          <div>
            {jobDetails ? (
              <ServiceNowComponent
                key={jobDetails.jobId}
                jobDetails={jobDetails}
                sourceName={selectedItem.sourceName}
                percentageJobDone={percentageJobDone}
              />
            ) : (
              <div
                className="loaderContainer"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "640px",
                }}
              >
                <Loader />
              </div>
            )}
          </div>
        );
      case "salesforce":
        // return <SalesForceComponent />;
        log("entered SF");
        return (
          <div>
            {jobDetails ? (
              <SalesForceComponent
                key={jobDetails.jobId}
                jobDetails={jobDetails}
                sourceName={selectedItem.sourceName}
                percentageJobDone={percentageJobDone}
              />
            ) : (
              <div
                className="loaderContainer"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "640px",
                }}
              >
                <Loader />
              </div>
            )}
          </div>
        );
      case "sharepoint":
        return (
          <div>
            {jobDetails ? (
              <SharePointComponent
                key={jobDetails.jobId}
                jobDetails={jobDetails}
                sourceName={selectedItem.sourceName}
                percentageJobDone={percentageJobDone}
              />
            ) : (
              <div
                className="loaderContainer"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "640px",
                }}
              >
                <Loader />
              </div>
            )}
          </div>
        );
      case "web":
        return (
          <div>
            {jobDetails ? (
              <WebsiteComponent
                jobDetails={jobDetails}
                percentageJobDone={percentageJobDone}
              />
            ) : (
              "Loading..."
            )}
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <section className="contentSourcesAddition">
      <div className="addingContentSourcesAddition">
        <div className="addingContentAdditionheading">
          <h4 className="addingContentAdditionheadingh4tag">Content Sources</h4>
          <AddNewContentModal
            editable={editable}
            onContentSourceAdded={() => {
              log("Content source added. Refreshing...");
              fetchContentSources();
            }}
          />
          <div className="addedContentTypes">
            {contentSources.map((source) => (
              <AddedContent
                key={source.id}
                name={source.source}
                contentType={source.type}
                image={getImage(source.type)}
                isSelected={selectedItem.id === source.id}
                onSelect={() =>
                  handleSelect(source.id, source.source, source.type)
                }
              />
            ))}
          </div>
        </div>
        {contentSources.length > 0 ? (
          <div className="addedContentTypesDisplay">
            {getSelectedComponent()}
          </div>
        ) : (
          <div className="contentTypesImages">
            <h5>Add files or connect data sources</h5>
            <div className="contentSouresImages">
              <img
                src={SalesForce}
                alt="SalesForce"
                className="contentSourcesimage"
              />
              <img src={Azure} alt="Azure" className="contentSourcesimage" />
              <img
                src={ServiceNow}
                alt="ServiceNow"
                className="contentSourcesimage"
              />
              <img src={MySQL} alt="MySQL" className="contentSourcesimage" />
              <img
                src={SharePoint}
                alt="SharePoint"
                className="contentSourcesimage"
              />
            </div>
            <p className="contentTypesImagesPara">
              The data source may comprise uploaded files or data originating
              from Salesforce, ServiceNow, SharePoint, Azure Data Lake, MySQL,
              and other potential sources.
            </p>
          </div>
        )}
      </div>
    </section>
  );
}

export default ContentSourcesAddition;
