import React, { useState, useMemo, useEffect } from "react";
import { Tabs, Table, Button } from "antd";
import PdfIcon from "../assets/ContentSources/pdf.svg";
import DocxIcon from "../assets/ContentSources/docx.svg";
import TxtIcon from "../assets/ContentSources/txt.svg";
import "../styles/sharePointDocument.css";
const SharePointDocument = ({ stepTwosharePointData, onSelectionChange }) => {
  const [activeTab, setActiveTab] = useState("documents");
  const [indeterminate, setIndeterminate] = useState(false);
  const categorizedData = useMemo(
    () => ({
      documents: stepTwosharePointData.filter(
        (item) => item.type === "document"
      ),
      pages: stepTwosharePointData.filter((item) => item.type === "page"),
      lists: stepTwosharePointData.filter((item) => item.type === "list"),
    }),
    [stepTwosharePointData]
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState({
    documents: categorizedData.documents.map((doc) => doc.id),
    pages: categorizedData.pages.map((page) => page.id),
    lists: categorizedData.lists.map((list) => list.id),
  });
  useEffect(() => {
    const allSelectedIds = [
      ...selectedRowKeys.documents,
      ...selectedRowKeys.pages,
      ...selectedRowKeys.lists,
    ];
    onSelectionChange(allSelectedIds);
  }, [selectedRowKeys, onSelectionChange]);

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys((prevSelected) => ({
      ...prevSelected,
      [activeTab]: selectedKeys,
    }));
    setIndeterminate(
      selectedKeys.length > 0 &&
        selectedKeys.length < categorizedData[activeTab].length
    );
  };
  const getDocumentFormat = (name) => {
    const extension = name.split(".").pop();
    return extension.toUpperCase();
  };
  const getFileIcon = (format) => {
    switch (format) {
      case "PDF":
        return <img src={PdfIcon} alt="PDF" style={{ marginRight: 8 }} />;
      case "TXT":
        return <img src={DocxIcon} alt="DOCX" style={{ marginRight: 8 }} />;
      // case "TXT":
      //   return <img src={TxtIcon} alt="TXT" style={{ marginRight: 8 }} />;
      default:
        return null;
    }
  };
  const baseColumns = [
    {
      title: "TITLE",
      dataIndex: "name",
      key: "name",
      width: "80%",
      render: (text, record) => {
        const icon = getFileIcon(getDocumentFormat(record.name));
        return (
          <span className="sharePointDocumentTitle">
            {icon}
            {text}
          </span>
        );
      },
    },
    {
      title: "ACTION",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <Button type="link" href={record.url} target="_blank">
          View
        </Button>
      ),
    },
  ];
  const documentColumns = [
    baseColumns[0],
    {
      title: "FORMAT",
      dataIndex: "format",
      key: "format",
      width: "10%",
      render: (_, record) => getDocumentFormat(record.name) || "N/A",
    },
    baseColumns[1],
  ];
  const paginationConfig = {
    pageSize: 20,
    showSizeChanger: false,
  };

  const tabItems = Object.keys(categorizedData).map((type) => ({
    label: `${type.charAt(0).toUpperCase() + type.slice(1)} (${
      categorizedData[type].length
    })`,
    key: type,
    children: (
      <Table
        rowKey="id"
        columns={activeTab === "documents" ? documentColumns : baseColumns}
        dataSource={categorizedData[type]}
        pagination={paginationConfig}
        rowSelection={{
          selectedRowKeys: selectedRowKeys[activeTab],
          onChange: onSelectChange,
          getCheckboxProps: (record) => ({
            indeterminate: false,
          }),
        }}
      />
    ),
  }));

  return (
    <div className="sharePointDocuments">
      <Tabs
        defaultActiveKey="documents"
        onChange={(key) => setActiveTab(key)}
        items={tabItems}
      />
    </div>
  );
};

export default SharePointDocument;
