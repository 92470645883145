import React, { useCallback, useState } from "react";
import { Input, Table, Tooltip, Progress } from "antd";
import WebsiteLogo from "../../assets/ContentIngestionTypes/website-icn.svg";
import FilterLogo from "../../assets/ContentSources/filter.svg";
import { SearchOutlined } from "@ant-design/icons";
import "../../styles/ContentSourcesComponentsStyles/websiteComponent.css";
import log  from "../logger";

const WebsiteComponent = ({ jobDetails, percentageJobDone }) => {
  // log("logging jobdetails inside web component", jobDetails);
  const [searchText, setSearchText] = useState("");
  const [expandedStatus, setExpandedStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };
  const data = jobDetails.processedArticles.map((connector, index) => ({
    key: index,
    number: index + 1,
    urls: connector.url,
    status: connector.status,
  }));
  const errorCount = jobDetails.processedArticles.filter(
    (article) =>
      article.status !== "ingested" &&
      article.status !== "in progress" &&
      article.status !== "queued" &&
      article.status !== "unprocessable"
  ).length;
  const columns = [
    {
      title: "NUMBER",
      dataIndex: "number",
      key: "number",
      width: "10%",
    },
    {
      title: "URLs",
      dataIndex: "urls",
      width: "44%",
      key: "urls",
      render: (text, record) => (
        <div
          style={{
            maxWidth: "560px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer",
          }}
          onClick={() =>
            setExpandedStatus(expandedStatus === record.key ? null : record.key)
          }
        >
          {expandedStatus === record.key ? (
            text
          ) : (
            <Tooltip title={text}>{text}</Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      width: "10%",
      key: "status",
      render: (status) => {
        let color = "black";
        let displayStatus = status;
        if (status === "ingested") {
          color = "green";
        } else if (
          status === "queued" ||
          status === "in progress" ||
          status === "unprocessable"
        ) {
          color = "black";
        } else {
          color = "red";
          displayStatus = "Error";
        }
        return <span style={{ color }}>{displayStatus}</span>;
      },
    },
    {
      title: "",
      key: "view",
      width: "10%",
      render: (text, record) => (
        <a href={record.urls} target="_blank" rel="noopener noreferrer">
          View
        </a>
      ),
    },
  ];
  const filteredData = searchText
    ? data.filter((item) =>
        item.urls.toLowerCase().includes(searchText.toLowerCase())
      )
    : data;
  const paginationConfig = {
    pageSize: 20,
    showSizeChanger: false,
  };

  return (
    <section className="WebsiteComponent">
      <div className="WebsiteComponentHeader">
        <div className="WebsiteComponentSubHeader">
          <img src={WebsiteLogo} alt="Website" />
          <h4>Product Articles</h4>
        </div>
        <div className="WebsiteComponentHeaderLastSynced">
          <span className="WebsiteSpanLastSynced">Last Synced :</span>
          <span>2nd Oct 2023 / 10:40 am</span>
        </div>
      </div>
      <div className="WebsiteComponentMetrics">
        <div className="WebsiteComponentMetrics1">
          <span>Job Id</span>
          <h5>{jobDetails.jobId}</h5>
        </div>
        <div className="WebsiteComponentMetrics2">
          <span>Total Number of Urls</span>
          <h5>{jobDetails.numberOfArticles || 0}</h5>
        </div>
        <div className="WebsiteComponentMetrics3">
          <span>Errors</span>
          <h5>{errorCount}</h5>
        </div>
      </div>
      <div>
        <div className="WebsiteComponentFilter">
          <div className="WebsiteComponentFilterHeader">
            <img src={FilterLogo} alt="Filter" />
            <span>Webpages</span>
            {percentageJobDone < 100 && (
              <div
                style={{ display: "flex", marginLeft:"2%", alignItems: "center", width: "40%" }}
              >
                <Progress
                  percent={percentageJobDone}
                  size={[120, 10]}
                  status="active"
                  className="progressBar"
                  showInfo={false}
                />
                <span
                  style={{ marginLeft: 25 }}
                >{`${percentageJobDone}%`}</span>
              </div>
            )}
          </div>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={handleSearchInputChange}
            onPressEnter={() => filteredData(searchText)}
            style={{ width: 200, margin: "0 0" }}
            loading={loading}
            allowClear
          />
        </div>
        <div className="WebsiteComponentTable">
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={paginationConfig}
            rowKey="key"
            loading={loading}
          />
        </div>
      </div>
    </section>
  );
};

export default WebsiteComponent;
