import React, { useState, useCallback, useRef, useEffect } from "react";
import { Input, Button, Checkbox, Alert } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useAuth } from "../AuthContext";
import DropDownComponent from "./DropDownComponent";
import Loader from "./Loader";
import AnswerConfigComponent from "./AnswerConfigComponent";
import ServiceNowLogo from "../assets/ContentIngestionTypes/servicenow-icn.svg";
import "../styles/serviceNowConnectors.css";
import log from "./logger";

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}
export const StepOneServiceNow = ({
  onNext,
  onBack,
  onBackToOptions,
  setJobId,
  setknowledgeBases,
  formData,
  setFormData
}) => {
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const { tokenDetails } = useAuth();
  const [alert, setAlert] = useState({
    visible: false,
    message: "",
  });
  //this is to make the alert disapper
  useEffect(() => {
    if (alert.visible) {
      const timer = setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [alert]);
  const isFieldValid = (value) => {
    if (typeof value === "boolean") return true;
    if (typeof value === "string") return value.trim() !== "";
    return false;
  };
  const validateForm = (formData) => {
    const sorConfigValid = Object.values(formData.sorConfig).every(
      isFieldValid
    );
    const answersDetailsValid = Object.values(formData.answersDetails).every(
      isFieldValid
    );
    return sorConfigValid && answersDetailsValid;
  };
  const handleChange = (event, section) => {
    const { name, value } = event.target;
    setFormData((prevState) => {
      const updatedFormData = {
        ...prevState,
        [section]: {
          ...prevState[section],
          [name]: value,
        },
      };
      const formIsValid = validateForm(updatedFormData);
      setIsFormValid(formIsValid);
      return updatedFormData;
    });
    sessionStorage.setItem('formData', JSON.stringify(formData));
  };
  const updateAnswersDetails = useCallback((details) => {
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        answersDetails: { ...details },
      };
      const formIsValid = validateForm(updatedFormData);
      setIsFormValid(formIsValid);
      return updatedFormData;
    });
    sessionStorage.setItem('formData', JSON.stringify(formData));
  }, []);

  const handleSubmit = async (event) => {
    log(formData);
    event.preventDefault();
    setLoading(true);
    setAlert({ visible: false, message: "" });
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/initiateJob?userId=${tokenDetails.user.id}&agentId=${tokenDetails.agent_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to initiate job");
      }
      setJobId(data.jobId);
      sessionStorage.setItem("initiatedJobId", data.jobId); //This is used to delete job if page reloaded midway/ user press cancel
      setknowledgeBases(data.knowledgeBases);
      log("Data after sending in first step servicenow:", data);
      await timeout(1000);
      setLoading(false);
      onNext();
    } catch (error) {
      setLoading(false);
      log("Failed to send data", error);
      setAlert({ visible: true, message: error.message });
    }
  };
  return (
    <section className="serviceNowConnectors">
      {loading ? (
        <div
          className="loaderContainer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {alert.visible && (
            <Alert
              message="Error"
              description={alert.message}
              type="error"
              closable
              showIcon
              style={{
                position: "fixed",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
                // zIndex: 1000,
                margin: "16px",
                padding: "8px 16px",
                fontSize: "14px",
                width: "70%",
                maxWidth: "500px",
              }}
            />
          )}
          <div className="serviceNowConnectorsHeader">
            <div className="serviceNowConnectorsContent">
              <h3>Configure Connection</h3>
              <p>
                Simplify the configuration of your connections by entering
                essential connection details, ensuring a seamless setup process
                for your selected data source
              </p>
            </div>
            <div className="serviceNowLogo">
              <img src={ServiceNowLogo} alt="ServiceNow" />
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="serviceNowConnectorsInput">
              <div className="serviceNowConnectorsInput1">
                <h4>Name<span style={{ color: 'red' }}>*</span></h4>
                <Input
                  placeholder="Enter a Name for the Job"
                  name="source"
                  value={formData.source || formData.sorConfig.source}
                  onChange={(e) => handleChange(e, "sorConfig")}
                />
                <h4>Source Name<span style={{ color: 'red' }}>*</span></h4>
                <Input
                  placeholder="Enter the Servicenow Source name"
                  name="sourceName"
                  value={formData.sourceName || formData.sorConfig.sourceName}
                  onChange={(e) => handleChange(e, "sorConfig")}
                />
              </div>
              <div className="serviceNowConnectorsInput2">
                <div className="serviceNowUserName">
                  <h4>User Name<span style={{ color: 'red' }}>*</span></h4>
                  <Input
                    placeholder="Enter User Name"
                    name="username"
                    value={formData.username || formData.sorConfig.username}
                    onChange={(e) => handleChange(e, "sorConfig")}
                  />
                </div>
                <div className="serviceNowPassword">
                  <h4>Password<span style={{ color: 'red' }}>*</span></h4>
                  <Input.Password
                    placeholder="Enter Password"
                    name="password"
                    value={formData.password || formData.sorConfig.password}
                    onChange={(e) => handleChange(e, "sorConfig")}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </div>
              </div>
            </div>
            <AnswerConfigComponent
            //pass document group id here
            documentGroupId={formData.answersDetails.documentGroupId}
              updateAnswersDetails={updateAnswersDetails}
            />
            <div className="serviceNowButtons">
              <Button
                onClick={() => (onBackToOptions ? onBackToOptions() : onBack())}
              >
                Back
              </Button>
              <Button type="primary" htmlType="submit" disabled={!isFormValid}>
                Next
              </Button>
            </div>
          </form>
        </>
      )}
    </section>
  );
};
export const StepTwoServiceNow = ({
  onBack,
  knowledgeBases,
  onNext,
  jobId,
  setServiceNowData,
  setInitialUserProperties,
  setFormData
}) => {
  const { TextArea } = Input;
  const { tokenDetails } = useAuth();
  const [checkboxes, setCheckboxes] = useState(
    knowledgeBases.map((kb) => ({
      label: kb.title,
      sys_id: kb.sys_id,
      checked: false,
    }))
  );
  const [customQuery, setCustomQuery] = useState("");
  const [published, setPublished] = useState(false);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vaildToDate, setVaildToDate] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [alert, setAlert] = useState({
    visible: false,
    message: "",
  });
  //this is to make the alert disapper
  useEffect(() => {
    if (alert.visible) {
      const timer = setTimeout(() => {
        setAlert({ visible: false, message: "" });
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [alert]);
  const onCustomQueryChange = (event) => {
    setCustomQuery(event.target.value);
  };
  const onOnlyPublished = (event) => {
    setPublished(event.target.checked);
  };
  const onOnlyActive = (event) => {
    setActive(event.target.checked);
  };
  const onOnlyValidToDate = (event) => {
    setVaildToDate(event.target.checked);
  };
  const onCheckboxChange = (sys_id) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.sys_id === sys_id
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    );
    setCheckboxes(updatedCheckboxes);
  };
  useEffect(() => {
    const anyCheckboxChecked = checkboxes.some((checkbox) => checkbox.checked);
    const isAnyAdvancedFilterSelected = published || active || vaildToDate;
    const isCustomQueryEntered = customQuery.trim().length > 0;
    setIsFormValid(
      anyCheckboxChecked || isAnyAdvancedFilterSelected || isCustomQueryEntered
    );
  }, [checkboxes, published, active, vaildToDate, customQuery]);

  const fetchInitialOptions = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
    };
    let res = await fetch(
      `${process.env.REACT_APP_API_URL}/getUserProperties?agentId=${tokenDetails.agent_id}&accessToken=${tokenDetails.user.access_token}&instance=${tokenDetails.instance}`,
      requestOptions
    );
    res = await res.json();
    log("logging user properties options>", res);
    return res;
  };

  const handleNext = async (event) => {
    event.preventDefault();
    setLoading(true);
    const kbSysIds = checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.sys_id);
    
    log("isCustomQueryEntered", customQuery, /\bhttps:\/\/(www\.)?([a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,})(\/[a-zA-Z0-9\-\.]*)*\b/.test(customQuery));
    if(customQuery && !/\bhttps:\/\/(www\.)?([a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,})(\/[a-zA-Z0-9\-\.]*)*\b/.test(customQuery)){
      // await timeout(1000);
      setLoading(false);
      setAlert({ visible: true, message: "Invalid URL for custom Query"});
    }else{
      const serviceNowData = {
        kbSysIds,
        advancedFilters: {
          published,
          active,
          vaildToDate,
        },
        customQuery,
      };
      log("Logging the serviceNow data:", serviceNowData);
      setServiceNowData(serviceNowData);
      const initalUserOptions = await fetchInitialOptions();
      setInitialUserProperties(initalUserOptions);
      await timeout(2000);
      setLoading(false);
      onNext();
    }

  };

  const deleteJob = async (jobId) => {
    log("entered back to options");
    const requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/deleteJob?jobId=${jobId}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (err) {
      log("error while deleting job", err);
    }
  };

  const handleBack = () => {
    const savedFormData = sessionStorage.getItem('formData');
    // log("entered handle back step 2>>", savedFormData);
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
    onBack();
    log("logging jobid in back step", jobId);
    deleteJob(jobId);
    sessionStorage.removeItem("initiatedJobId");
  };

  return (
    <section className="stepTwoserviceNow">
      {loading ? (
        <div
          className="loaderContainer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {alert.visible && (
              <Alert
                message="Error"
                description={alert.message}
                type="error"
                closable
                showIcon
                style={{
                  position: "fixed",
                  top: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  // zIndex: 1000,
                  margin: "16px",
                  padding: "8px 16px",
                  fontSize: "14px",
                  width: "70%",
                  maxWidth: "500px",
                }}
              />
            )}
          <div className="stepTwoserviceNowConnectorsHeader">
            <div className="stepTwoserviceNowConnectorsContent">
              <h3>Filter Articles</h3>
              <p>
                Optimize your search experience with the 'Filter Articles'
                functionality, enabling you to choose from various knowledge
                bases and implement advanced filters for precise and tailored
                results.
              </p>
            </div>
            <div className="serviceNowLogo">
              <img src={ServiceNowLogo} alt="ServiceNow" />
            </div>
          </div>
          <div className="stepTwoServiceNowCheckBox">
            <h4>By Knowledge Base</h4>
            <div className="stepTwoServiceNowCheckBoxContent">
              <div className="serviceNowCheckBoxDiv">
                {checkboxes.map((checkbox) => (
                  <Checkbox
                    key={checkbox.sys_id}
                    checked={checkbox.checked}
                    className="serviceNowCheckBox"
                    onChange={() => onCheckboxChange(checkbox.sys_id)}
                  >
                    {checkbox.label}
                  </Checkbox>
                ))}
              </div>
            </div>
          </div>
          <div className="stepTwoServiceNowAdvancedFilters">
            <h4>Advanced Filter</h4>
            <div className="stepTwoServiceNowAdvancedFiltersCheckBox">
              <Checkbox
                className="serviceNowCheckBox"
                checked={published}
                onChange={onOnlyPublished}
              >
                Published
              </Checkbox>
              <Checkbox
                className="serviceNowCheckBox"
                checked={active}
                onChange={onOnlyActive}
              >
                Active
              </Checkbox>
              <Checkbox
                className="serviceNowCheckBox"
                checked={vaildToDate}
                onChange={onOnlyValidToDate}
              >
                Valid to date
              </Checkbox>
            </div>
          </div>
          <div className="stepTwoServiceNowCustomInput">
            <h4>Custom Query to Select the Knowledge Articles</h4>
            <TextArea
              value={customQuery}
              onChange={onCustomQueryChange}
              autoSize={{
                minRows: 3,
              }}
              style={{ width: "100%", height: "100%", overflow: "hidden" }}
            />
          </div>
          <div className="stepTwoServiceNowButtons">
            <Button onClick={handleBack}>Back</Button>
            <Button type="primary" onClick={handleNext} disabled={!isFormValid}>
              Next
            </Button>
          </div>
        </>
      )}
    </section>
  );
};

export const StepThreeServiceNow = ({
  onBack,
  jobId,
  closeModal,
  serviceNowData,
  initialUserProperties,
}) => {
  const [loading, setLoading] = useState(false);
  const { tokenDetails } = useAuth();
  const formRef = useRef(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (formRef.current && formRef.current.getFormData) {
      const formData = formRef.current.getFormData();
      const combinedData = {
        ...serviceNowData,
        docAttributes: formData,
      };
      log("Combined data ready for API call in servicenow", combinedData);
      try {
        log("formData>", formData);
        log("initialValues>", initialUserProperties);
        let intialValues = initialUserProperties.map((item) => item.label);
        let customAttributes = Object.keys(formData).filter((item) => {
          return (
            formData[item] &&
            formData[item] !== "" &&
            !intialValues.includes(item)
          );
        });
        log("custom attributes>", customAttributes);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/setUserProperty?agentId=${tokenDetails.agent_id}&accessToken=${tokenDetails.user.access_token}&instance=${tokenDetails.instance}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(customAttributes),
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        //delay for sometime to ensure user propety is created on platform before assigning value

        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/servicenow/filtersSelect?jobId=${jobId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(combinedData),
          }
        );
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        const result = res.status;
        log("Thrid step servicenow status received", result);
        sessionStorage.removeItem("initiatedJobId"); //Since job is in progress, we are clearing the initiatedJobId
        closeModal();
      } catch (error) {
        console.error("Failed to send data to API:", error);
      } finally {
        await timeout(1000);
        setLoading(false);
        closeModal();
      }
    }
  };
  return (
    <section className="stepThreeserviceNow">
      {loading ? (
        <div
          className="loaderContainer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div className="stepThreeserviceNowConnectorsHeader">
            <div className="stepThreeserviceNowConnectorsContent">
              <h3>Set Document Attributes</h3>
              <p>
                Select document attributes applicable to the documents. These
                will be used for filtering based on user entitlements. Note that
                the attributes selected below are applicable across all
                documents selected for ingestion.
                <br/>
                Note: Attributes, if changed at the document group level will be overwritten each time the article gets updated.
              </p>
            </div>
            <div className="serviceNowLogo">
              <img src={ServiceNowLogo} alt="ServiceNow" />
            </div>
          </div>
          <div className="stepThreeserviceNowDocPropertiesHeading">
            <h4>Choose from the following properties</h4>
          </div>
          <div className="stepThreeserviceNowDocPropertiesSetting">
            <DropDownComponent
              ref={formRef}
              initialOptions={initialUserProperties}
            />
          </div>
          <div className="stepThreeServiceNowButtons">
            <Button onClick={onBack}>Back</Button>
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </>
      )}
    </section>
  );
};
const ServiceNowConnectors = ({
  onBackToOptions,
  updateSubStep,
  closeModal,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [knowledgeBases, setknowledgeBases] = useState([]);
  const [jobId, setJobId] = useState(0);
  const [serviceNowData, setServiceNowData] = useState(null);
  const [initialUserProperties, setInitialUserProperties] = useState(null);
  const [formData, setFormData] = useState({
    type: "servicenow",
    sorConfig: {
      source: "",
      sourceName: "", //This is the snow source name
      username: "",
      password: "",
      ingestCatalog: false,
      documentType: "Knowledge Articles",
    },
    answersDetails: {
      documentGroupId: "",
      apiServer: "",
      accessToken: "",
    },
  });
  const nextStep = () => {
    const newStep = currentStep + 1;
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
      updateSubStep(newStep);
    }
  };
  const prevStep = () => {
    const newStep = currentStep - 1;
    if (newStep >= 0) {
      setCurrentStep(newStep);
      updateSubStep(newStep);
    } else {
      sessionStorage.removeItem('formData');
      onBackToOptions();
    }
  };
  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <StepOneServiceNow
            onNext={nextStep}
            onBack={prevStep}
            onBackToOptions={onBackToOptions}
            setJobId={setJobId}
            setknowledgeBases={setknowledgeBases}
            formData = {formData}
            setFormData = {setFormData}
          />
        );
      case 1:
        return (
          <StepTwoServiceNow
            onNext={nextStep}
            onBack={prevStep}
            knowledgeBases={knowledgeBases}
            jobId={jobId}
            setServiceNowData={setServiceNowData}
            setInitialUserProperties={setInitialUserProperties}
            setFormData={setFormData}
          />
        );
      case 2:
        return (
          <StepThreeServiceNow
            onBack={prevStep}
            jobId={jobId}
            closeModal={closeModal}
            serviceNowData={serviceNowData}
            initialUserProperties={initialUserProperties}
          />
        );
    }
  };
  return <>{renderStep()}</>;
};

export default ServiceNowConnectors;
